<form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
  <div class="custom-file-container" *ngIf="existingLogo; else uploadNewImage">
    <div class="image-wrapper m-auto w-1/3 rounded bg-gray-200 p-2">
      <img [src]="existingLogo.url" alt="" />
    </div>
    <div class="button-wrapper m-auto flex justify-center py-2">
      <button class="btn btn-danger" type="button" (click)="removeImage($event)">{{ "office-branch.remove_image" | translate }}</button>
    </div>
  </div>
  <ng-template #uploadNewImage>
    <div class="custom-file-container rounded bg-gray-100" data-upload-id="office-branch-logo" (click)="uploadClick($event)"></div>
  </ng-template>
  <div class="relative flex items-center pt-5">
    <span class="mr-4 flex-shrink font-bold text-gray-400">{{ "office-branch.branch_info" | translate }}</span>
    <div class="flex-grow border-t border-gray-400"></div>
  </div>
  <div class="pb-5">
    <small>{{ "office-branch.branch_info_desc" | translate }}</small>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''">
      <label for="name">{{ "office-branch.name" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_name' | translate }}" class="form-input" id="name" formControlName="name" />
      <ng-container *ngIf="form.controls['name'].touched && form.controls['name'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_name" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['guid'].touched ? (form.controls['guid'].errors ? 'has-error' : 'has-success') : ''">
      <label for="guid">{{ "office-branch.guid" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_guid' | translate }}" class="form-input" id="guid" formControlName="guid" />
      <ng-container *ngIf="form.controls['guid'].touched && form.controls['guid'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_guid" | translate }}</small>
        </p>
      </ng-container>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-3">
    <div [ngClass]="form.controls['bankAccount'].touched ? (form.controls['bankAccount'].errors ? 'has-error' : 'has-success') : ''">
      <label for="bankAccount">{{ "office-branch.bankAccount" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_bankAccount' | translate }}" class="form-input" id="bankAccount" formControlName="bankAccount" />
      <ng-container *ngIf="form.controls['bankAccount'].touched && form.controls['bankAccount'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_bankAccount" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['PIB'].touched ? (form.controls['PIB'].errors ? 'has-error' : 'has-success') : ''">
      <label for="PIB">{{ "office-branch.PIB" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_PIB' | translate }}" class="form-input" id="PIB" formControlName="PIB" />
      <ng-container *ngIf="form.controls['PIB'].touched && form.controls['PIB'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_PIB" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['fullAddress'].touched ? (form.controls['fullAddress'].errors ? 'has-error' : 'has-success') : ''">
      <label for="full_address">{{ "office-branch.full_address" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_full_address' | translate }}" class="form-input" id="full_address" formControlName="fullAddress" />
      <ng-container *ngIf="form.controls['fullAddress'].touched && form.controls['fullAddress'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_full_address" | translate }}</small>
        </p>
      </ng-container>
    </div>
  </div>

  <!-- START eTurist Requirement -->
  <!--<div [ngClass]="form.controls['city'].touched ? (form.controls['city'].errors ? 'has-error' : 'has-success') : ''">
      <label for="city">{{ "office-branch.city" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_city' | translate }}" class="form-input" id="city" formControlName="city" />
      <ng-container *ngIf="form.controls['city'].touched && form.controls['city'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_city" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['street'].touched ? (form.controls['street'].errors ? 'has-error' : 'has-success') : ''">
      <label for="street">{{ "office-branch.street" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_street' | translate }}" class="form-input" id="street" formControlName="street" />
      <ng-container *ngIf="form.controls['street'].touched && form.controls['street'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_street" | translate }}</small>
        </p>
      </ng-container>
    </div>
  </div> -->
  <!-- END eTurist Requirement -->
  <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3">
    <div [ngClass]="form.controls['phoneNumber'].touched ? (form.controls['phoneNumber'].errors ? 'has-error' : 'has-success') : ''">
      <label for="phoneNumber">{{ "office-branch.phoneNumber" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_phoneNumber' | translate }}" class="form-input" id="phoneNumber" formControlName="phoneNumber" />
      <ng-container *ngIf="form.controls['phoneNumber'].touched && form.controls['phoneNumber'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_phoneNumber" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''">
      <label for="email">{{ "office-branch.email" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_email' | translate }}" class="form-input" id="email" formControlName="email" />
      <ng-container *ngIf="form.controls['email'].touched && form.controls['email'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_email" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['siteUrl'].touched ? (form.controls['siteUrl'].errors ? 'has-error' : 'has-success') : ''">
      <label for="siteUrl">{{ "office-branch.siteUrl" | translate }}</label>
      <div class="flex">
        <div
          class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0"
        >
          https://www.
        </div>
        <input type="text" placeholder="{{ 'office-branch.enter_siteUrl' | translate }}" class="form-input ltr:rounded-l-none rtl:rounded-r-none" id="siteUrl" formControlName="siteUrl" />
      </div>
      <ng-container *ngIf="form.controls['siteUrl'].touched && form.controls['siteUrl'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_siteUrl" | translate }}</small>
        </p>
      </ng-container>
    </div>
  </div>
  <div class="relative flex items-center pt-5">
    <span class="mr-4 flex-shrink font-bold text-gray-400">{{ "office-branch.offical_agent_info" | translate }}</span>

    <div class="flex-grow border-t border-gray-400"></div>
  </div>
  <div class="pb-5">
    <small>{{ "office-branch.offical_agent_info_desc" | translate }}</small>
  </div>
  <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-4">
    <div [ngClass]="form.controls['senderEmail'].touched ? (form.controls['senderEmail'].errors ? 'has-error' : 'has-success') : ''">
      <label for="senderEmail">{{ "office-branch.senderEmail" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_senderEmail' | translate }}" class="form-input" id="senderEmail" formControlName="senderEmail" />
      <ng-container *ngIf="form.controls['senderEmail'].touched && form.controls['senderEmail'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_senderEmail" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['senderName'].touched ? (form.controls['senderName'].errors ? 'has-error' : 'has-success') : ''">
      <label for="senderName">{{ "office-branch.senderName" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_senderName' | translate }}" class="form-input" id="senderName" formControlName="senderName" />
      <ng-container *ngIf="form.controls['senderName'].touched && form.controls['senderName'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_senderName" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['adminEmail'].touched ? (form.controls['adminEmail'].errors ? 'has-error' : 'has-success') : ''">
      <label for="adminEmail">{{ "office-branch.adminEmail" | translate }}</label>
      <input type="text" placeholder="{{ 'office-branch.enter_adminEmail' | translate }}" class="form-input" id="adminEmail" formControlName="adminEmail" />
      <ng-container *ngIf="form.controls['adminEmail'].touched && form.controls['adminEmail'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_adminEmail" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['password'].touched ? (form.controls['password'].errors ? 'has-error' : 'has-success') : ''">
      <label for="password">{{ "office-branch.password" | translate }}</label>
      <input type="password" autocomplete="off" placeholder="{{ 'office-branch.enter_password' | translate }}" class="form-input" id="password" formControlName="password" />
      <ng-container *ngIf="form.controls['password'].touched && form.controls['password'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_password" | translate }}</small>
        </p>
      </ng-container>
    </div>
  </div>
  <button type="submit" class="btn btn-primary mt-3 w-full">
    {{ "office-branch.save" | translate }}
  </button>
</form>
