import { forkJoin, Observable } from "rxjs";
import { FormBuilder } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { colDef } from "@bhplugin/ng-datatable";
import { FlatpickrOptions } from "ng2-flatpickr";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StatisticsYearlyDebt } from "./models/yearlydebt";
import { StatisticsYear } from "./models/statisticsyear";
import { StatisticsMoney } from "./models/statisticsmoney";
import { MonthlyDebtStatistics } from "./models/monthlydebt";
import { DailyDebtStatistics } from "./models/dailydebt";
import { StatisticsDaily } from "./models/statisticmoneydaily";
import { DailyEarningsStatistics } from "./models/dailyearnings";
import { OverallStatistics } from "./models/overallstatistics";
import { StatisticsYearlyEarnings } from "./models/yearlyearning";
import { MonthlyEarningsStatistics } from "./models/monthlyearnings";
import { StatisticsMonthly } from "./models/statisticmoneymonthly";
import { StatisticsMoneyService } from "./services/statisticsmoney.service";
import { YearlyPopularDestinations } from "./models/yearlypopulardestinations";
import { MostPopularCountriesByMonth } from "./models/mostpopularcountriesbymonth";
import { StatisticOrganizer, StatisticOrganizerProfit } from "./models/statisticorganizer";

@Component({
  selector: "app-statisticsmoney",
  templateUrl: "./statisticsmoney.component.html",
  styleUrls: ["./statisticsmoney.component.css"],
  providers: [DatePipe],
})
export class StatisticsMoneyComponent implements OnInit {
  constructor(private statisticsService: StatisticsMoneyService, public translate: TranslateService, private fb: FormBuilder, private datePipe: DatePipe) {}
  protected statisticsData!: StatisticsYear;
  fromDate: any = "2020-01-01";
  toDate: any = "2024-01-01";
  numberOfDestinations?: any = 10;
  simpleColumnStacked: any;
  simpleColumnStackedOrganizer: any;
  public pageSize = 10;
  public pageNumber = 1;
  public filterType = "None";
  public totalRows = 1;
  public statisticsmoney: StatisticsMoney[] = [];
  search = "";
  availableYears: number[] = [];
  availableMonths: { label: string; value: number }[] = [];
  selectedYear?: any = new Date().getFullYear();
  selectedMonth: any = new Date().getMonth() + 1;
  yearlypopulardestination!: YearlyPopularDestinations | undefined;
  public year?: any;
  statisticsOrganizer: StatisticOrganizer[] = [];
  statisticsOrganizerProfit: StatisticOrganizerProfit[] = [];
  numberOfOrganizers?: any = 10;
  simpleColumnStackedOrganizerProfit: any;
  activeTab?: string = "Overall";
  activeTabs?: string = "yearly";
  overallStatistics: OverallStatistics | undefined;
  protected yearlyearning!: StatisticsYearlyEarnings;
  protected montlyearning!: MonthlyEarningsStatistics;
  protected monthlydebt!: MonthlyDebtStatistics;
  protected yearlydebt!: StatisticsYearlyDebt;
  protected dailyearnings!: DailyEarningsStatistics;
  protected dailyeardebt!: DailyDebtStatistics;
  protected monthlystatistic!: StatisticsMonthly;
  protected dailystatistic!: StatisticsDaily;
  mostPopularCountriesByMonth: MostPopularCountriesByMonth | undefined;
  simpleColumnStackedEarnings: any = {};
  simpleColumnStackedDebt: any = {};
  fromDatenew = new Date("2024-07-03");
  toDatenew = new Date("2024-07-10");
  datepick$!: Observable<{ from: Date; to: Date | undefined }>;

  flatpickrOptionsFrom: FlatpickrOptions = {
    dateFormat: "d. M Y",
    defaultDate: this.fromDate,
    onChange: (selectedDates: Date[]) => {
      this.fromDate = selectedDates[0].toISOString().split("T")[0];
    },
  };

  flatpickrOptionsTo: FlatpickrOptions = {
    dateFormat: "d. M Y",
    defaultDate: this.toDate,
    maxDate: new Date(),
    onChange: (selectedDates: Date[]) => {
      this.toDate = selectedDates[0].toISOString().split("T")[0];
    },
  };

  tabs: Array<{ id: string; label: string }> = [];
  tabsCountryDestination: Array<{ id: string; label: string }> = [];
  ngOnInit(): void {
    this.tabs = [
      { id: "Overall", label: "tabs_statistics.overall" },
      { id: "Monthlystatistics", label: "tabs_statistics.monthlystatistics" },
      { id: "Dailystatistics", label: "tabs_statistics.dailystatistics" },
    ];
    this.tabsCountryDestination = [
      { id: "yearly", label: "tabsCountryDestination.yearly" },
      { id: "monthly", label: "tabsCountryDestination.monthly" },
    ];

    forkJoin([
      this.initializeMonths(),
      this.getStatistics(),
      this.fetchstatisticsmoney(),
      this.getYearlyPopularDestinations(),
      this.getStatisticsOrganizer(),
      this.getStatisticsOrganizerProfit(),
      this.getOverallStatisticsData(),
      this.getyearlyearning(),
      this.getyearlydebt(),
      this.getmoneymonthly(),
      this.getmonthlyearning(),
      this.getmonthlydebt(),
      this.getdailyearning(),
      this.getdailydept(),
      this.getdailymoney(),
      this.applyDateFilter(),
      this.getMostPopularCountriesByMonth(),
    ]).subscribe(() => {
      //All data has been successfully retrieved. You may proceed with displaying or further processing.
    });
  }

  changeServer(data: any) {
    this.pageSize = data.pagesize;
    this.pageNumber = data.current_page;
    this.fetchstatisticsmoney();
  }

  fetchstatisticsmoney() {
    const fromDate = `${this.selectedYear}-01-01`;
    const toDate = `${this.selectedYear}-12-31`;
    const getMoneySubscription = this.statisticsService.getStatisticsMoneyWithHeaders(this.pageNumber, this.pageSize, fromDate, toDate, this.filterType).subscribe((response) => {
      this.statisticsmoney = response.body;
      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
      getMoneySubscription.unsubscribe();
    });
  }

  applyDateFilter() {
    if (this.fromDate && this.toDate) {
      const fromDateISO = this.datePipe.transform(this.fromDate, "yyyy-MM-dd");
      const toDateISO = this.datePipe.transform(this.toDate, "yyyy-MM-dd");
    }
    this.getOverallStatisticsData();
  }
  getOverallStatisticsData() {
    const overalSubscription = this.statisticsService.getOverallStatistics(this.fromDate, this.toDate).subscribe((response) => {
      overalSubscription.unsubscribe();
      this.overallStatistics = response;
    });
  }

  getStatistics() {
    const getStats = this.statisticsService.getStatistics().subscribe((response) => {
      getStats.unsubscribe();
      this.statisticsData = response;
      this.initializeYears();
    });
  }
  getyearlyearning() {
    const getEarningStatSub = this.statisticsService.getYearlyEarningStatistics().subscribe((response) => {
      getEarningStatSub.unsubscribe();
      this.yearlyearning = response;
    });
  }
  getmonthlyearning() {
    const getEarningsStatSub = this.statisticsService.getEarningsMonthStatistics(this.selectedYear).subscribe((response) => {
      getEarningsStatSub.unsubscribe();
      this.montlyearning = response;
    });
  }
  getmoneymonthly() {
    const moneyMonthStatSub = this.statisticsService.getMoneyMonthStatistics(this.selectedYear).subscribe((response) => {
      moneyMonthStatSub.unsubscribe();
      this.monthlystatistic = response;
    });
  }
  getdailyearning() {
    const getEarningStatSub = this.statisticsService.getEarningsDailyStatistics(this.selectedYear, this.selectedMonth).subscribe((response) => {
      getEarningStatSub.unsubscribe();
      this.dailyearnings = response;
    });
  }
  getdailymoney() {
    const getMoneyDailyStatSub = this.statisticsService.getMoneyDailyStatistics(this.selectedYear, this.selectedMonth).subscribe((response) => {
      getMoneyDailyStatSub.unsubscribe();
      this.dailystatistic = response;
    });
  }
  getmonthlydebt() {
    const getDebtmonthStatSub = this.statisticsService.getDebtMonthStatistics(this.selectedYear).subscribe((response) => {
      getDebtmonthStatSub.unsubscribe();
      this.monthlydebt = response;
    });
  }
  getdailydept() {
    const getDebtDailyStatSub = this.statisticsService.getDebtDailyStatistics(this.selectedYear, this.selectedMonth).subscribe((response) => {
      getDebtDailyStatSub.unsubscribe();
      this.dailyeardebt = response;
    });
  }
  getyearlydebt() {
    const getYearlyDebtStatSub = this.statisticsService.getYearlyDebtStatistics().subscribe((response) => {
      getYearlyDebtStatSub.unsubscribe();
      this.yearlydebt = response;
    });
  }
  getStatisticsOrganizer(): void {
    const getOrganizerStatSub = this.statisticsService.getOrganizerStatistics(this.numberOfOrganizers).subscribe((response) => {
      getOrganizerStatSub.unsubscribe();
      this.statisticsOrganizer = response;
    });
  }
  getStatisticsOrganizerProfit(): void {
    const getOrganizerProfitStatSub = this.statisticsService.getStatisticsOrganizerProfit(this.numberOfOrganizers).subscribe((response) => {
      getOrganizerProfitStatSub.unsubscribe();
      this.statisticsOrganizerProfit = response;
    });
  }
  onTabChange(tab: string) {
    this.activeTab = tab;
  }
  onTabChanges(tab: string) {
    this.activeTabs = tab;
  }

  getYearlyPopularDestinations() {
    const getYearlyPopularDestSub = this.statisticsService.getYearlyPopularDestinations(this.selectedYear, this.numberOfDestinations).subscribe((response) => {
      getYearlyPopularDestSub.unsubscribe();
      this.yearlypopulardestination = response;
    });
  }
  getMostPopularCountriesByMonth() {
    const getMostPopularConutriesSub = this.statisticsService.getMostPopularCountriesByMonth(this.selectedYear, this.numberOfDestinations).subscribe((response) => {
      getMostPopularConutriesSub.unsubscribe();
      this.mostPopularCountriesByMonth = response;
    });
  }
  onNumberOfOrganizersChange(number: number): void {
    this.numberOfOrganizers = number;
    this.getStatisticsOrganizer();
    this.getStatisticsOrganizerProfit();
  }

  updateColumn(col: colDef) {
    col.hide = !col.hide;
    this.cols = [...this.cols];
  }

  selectYear(year: number) {
    this.selectedYear = year ? year : this.availableYears[this.availableYears.length - 1];
    this.year = this.selectedYear;
    this.fromDate = `${this.selectedYear}-01-01`;
    this.toDate = `${this.selectedYear}-12-31`;
    this.fetchstatisticsmoney();
    this.getYearlyPopularDestinations();
    this.getmoneymonthly();
    this.getmonthlydebt();
    this.getmonthlyearning();
    this.getdailydept();
    this.getdailymoney();
    this.getdailyearning();
    this.getMostPopularCountriesByMonth();
  }

  selectMonth(month: number) {
    this.selectedMonth = month ? month : this.availableMonths[this.availableMonths.length - 1];
    this.getdailydept(), this.getdailymoney(), this.getdailyearning();
  }

  selectNumberOfDestinations(number: number) {
    this.numberOfDestinations = number;
    this.getStatistics();
    this.getYearlyPopularDestinations();
    this.getMostPopularCountriesByMonth();
  }

  cols = [
    { field: "id", title: "ID", isUnique: true, hide: true },
    {
      field: "entity_name",
      title: this.translate.instant("entity"),
      hide: false,
    },
    {
      field: "customerFullName",
      title: this.translate.instant("customer"),
      hide: false,
    },
    {
      field: "destinations",
      title: this.translate.instant("destination.name"),
      hide: false,
    },
    {
      field: "number_of_passangers",
      title: this.translate.instant("num_of_passangers"),
      hide: false,
    },
    {
      field: "getStatus",
      title: this.translate.instant("status"),
      hide: false,
    },
    { field: "bruto", title: "Bruto", hide: false },
    { field: "depth", title: "Dept", hide: false },
    {
      field: "createdAtFormated",
      title: this.translate.instant("sold"),
      hide: false,
    },
  ];
  onYearChange(year: number) {
    this.selectedYear = year;
    this.getStatistics();
    this.getYearlyPopularDestinations();
    this.getMostPopularCountriesByMonth();
    this.getmoneymonthly();
    this.getmonthlydebt();
    this.getmonthlyearning();
    this.getdailydept();
    this.getdailymoney();
    this.getdailyearning();
  }

  onMonthChange(month: number) {
    this.selectedMonth = month;
    this.getdailydept(), this.getdailymoney(), this.getdailyearning();
  }

  onNumberOfDestinationsChange(number: number) {
    this.numberOfDestinations = number;
    this.getStatistics();
    this.getYearlyPopularDestinations();
  }

  initializeYears() {
    if (this.statisticsData && this.statisticsData.yearlyServiceStats) {
      const years = this.statisticsData.yearlyServiceStats.map((item) => item.year);
      this.availableYears = Array.from(new Set(years));
      this.selectedYear = this.availableYears[this.availableYears.length - 1];
    }
  }

  initializeMonths() {
    this.translate.get("months").subscribe((translations: any) => {
      this.availableMonths = Object.keys(translations).map((key) => ({
        label: translations[key],
        value: +key,
      }));
      const currentMonth = new Date().getMonth() + 1;
      this.selectedMonth = this.availableMonths.find((month) => month.value === currentMonth)?.value;
    });
  }
}
