import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-settings-list",
  templateUrl: "./settings-list.component.html",
})
export class SettingsListComponent {
  activeTab = "office-branches";
  accordion: number | undefined = 1;
  constructor(public translate: TranslateService) {}

  updateAccordion(newRow: number | undefined) {
    this.accordion = newRow;
  }

  typeList = ["room-type", "room-content", "arrangement", "service", "other-services", "payment-type"];
  titleList = ["settings.room_type", "settings.room_content", "settings.arrangements", "settings.service", "settings.other_service", "settings.payment_type"];
}
