import { UserWorker } from "../models/worker";
import { Sale } from "../../sales/models/sale";
import { colDef } from "@bhplugin/ng-datatable";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { WorkersService } from "../services/workers.service";
import { MessagePortalComponent } from "src/app/portal/portal";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { RouterLinkHelper } from "src/app/shared/utils/router-link-helper";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-workers",
  templateUrl: "./worker.component.html",
  animations: [toggleAnimation],
})
export class WorkersComponent implements OnInit {
  constructor(public workersService: WorkersService, private route: ActivatedRoute, public translate: TranslateService, private positioningService: PositioningService, private router: Router) { }


  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  search = "";
  public sales!: Sale[];
  public worker: UserWorker = {} as UserWorker;
  public pageSize = 10;
  public pageNumber = 1;
  public totalRows = 1;
  public analytics: any;
  public entityCounter: any;
  public filterType = "None";
  public id!: number;
  public fullnameWorker!: string;
  public workers: any;
  public workerStatsData: any;
  public workerStats: any;
  public monthlyChartData: any;
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.getWorkerWithStatistics();
      this.getWorkerById();
      this.loadWorkerStats();
    });
  }
  cols = [
    { field: "id", title: "ID", isUnique: true, hide: true },
    {
      field: "entity_name",
      title: this.translate.instant("entity"),
      hide: false,
    },
    {
      field: "customerFullName",
      title: this.translate.instant("customer"),
      hide: false,
    },
    {
      field: "destinations",
      title: this.translate.instant("destination.name"),
      hide: false,
    },
    {
      field: "number_of_passangers",
      title: this.translate.instant("num_of_passangers"),
      hide: true,
    },
    {
      field: "getStatus",
      title: this.translate.instant("status"),
      hide: true,
    },
    { field: "bruto", title: "Bruto", hide: false },
    { field: "depth", title: "Dept", hide: false },
    {
      field: "createdAtFormated",
      title: this.translate.instant("sold"),
      hide: true,
    },
    { field: "actions", title: this.translate.instant("worker_info.actions"), hide: false },
  ];

  updateColumn(col: colDef) {
    col.hide = !col.hide;
    this.cols = [...this.cols];
  }
  changeServer(data: any) {
    this.pageSize = data.pagesize;
    this.pageNumber = data.current_page;
    this.getWorkerWithStatistics();
  }

  getWorkerWithStatistics() {
    this.workersService.getWorkerSalesWithHeaders(this.id, this.pageNumber, this.pageSize).subscribe((response) => {
      this.sales = response.body;
      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
      this.analytics = pagination.analytics;
      this.entityCounter = pagination.entityCounter;
    });
  }

  getWorkerById() {
    this.workersService.getWorkerById(this.id).subscribe((response) => {
      this.worker = response;
      this.fullnameWorker = `${this.worker.name} ${this.worker.surname}`;
    });
  }
  loadWorkerStats() {
    const fromDate = "2020-01-01";
    const toDate = "2029-01-01";
    this.workersService.getWorkerStats(this.id, fromDate, toDate).subscribe((response) => {
      this.workerStats = response;
      this.prepareMonthlyChartData();
    });
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
  navigateToEdit(value: any) {
    switch (value.entity_name) {
      case "Ticket":
        this.router.navigate([`dashboard/tickets/${value.type}/edit/${value.entityId}`]);
        break;
      case "Travel":
        this.router.navigate([`dashboard/travels/edit/${value.entityId}`]);
        break;
      case "GroupTravel":
        this.router.navigate([`dashboard/group-travels/${value.entityId}/plan/edit`]);
        break;
      case "Transfer":
        this.router.navigate([]); // TODO: Add when update/edit logic is added
        break;
      case "PlanService":
        this.router.navigate([`dashboard/other-services/edit/${value.entityId}`]);
        break;
      default:
        break;
    }
  }

  navigateToDetails(value: any) {
    switch (value.entity_name) {
      case "Ticket":
        this.router.navigate([`dashboard/tickets/${value.type}/${value.entityId}`]);
        break;
      case "Travel":
        this.router.navigate([`dashboard/travels/${value.entityId}`]);
        break;
      case "GroupTravel":
        this.router.navigate([`dashboard/group-travels/${value.entityId}`]);
        break;
      case "Transfer":
        this.router.navigate([`dashboard/transfers/${value.entityId}`]);
        break;
      case "PlanService":
        this.router.navigate([`dashboard/other-services/${value.entityId}`]);
        break;
      default:
        break;
    }
  }
  getRouterLink(value: any): string | undefined {
    return RouterLinkHelper.getRouterLink(value);
  }
  getIconAndTooltip(entityName: string) {
    return RouterLinkHelper.getIconAndTooltip(entityName);
  }
  prepareMonthlyChartData() {
    const currentYear = new Date().getFullYear();

    if (!this.workerStats || !Array.isArray(this.workerStats.webChartData)) {
      console.error("Invalid workerStats or webChartData");
      return;
    }

    // Initialize monthlyChartData with 12 months
    this.monthlyChartData = Array.from({ length: 12 }, (_, index) => ({
      year: currentYear,
      month: index + 1,
      numberOfPlans: 0,
    }));

    // Filter and populate monthlyChartData
    this.workerStats.webChartData.forEach((data: any) => {
      if (data.year === currentYear) {
        const monthIndex = data.month - 1;
        this.monthlyChartData[monthIndex].numberOfPlans += data.numberOfPlans;
      }
    });
  }

}
