import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilService } from "src/app/shared/services/util.service";
import { EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Customer } from "src/app/domain/customers/models/customer";
import { CustomersService } from "../services/customers.service";

@Component({
  selector: "app-customer-create-edit",
  templateUrl: "./customer-create-edit.component.html"
})
export class CustomerCreateEditComponent implements OnInit {
  @Input() customerId!: number;
  @Input() destination: Customer = {} as Customer;
  @Input() isLoading!: boolean;
  @Input() editMode!: boolean;
  @Input() entityData: any = {};
  @Output() formSubmit = new EventEmitter<any>();
  form!: FormGroup;
  EntityPicklistType = EntityPicklistType;
  constructor(private fb: FormBuilder, public utilService: UtilService, public customersService: CustomersService) { }
  ngOnInit(): void {
    this.initForm();
    if (this.customerId) {
      this.customersService.getCustomerDetails(this.customerId).subscribe((customer) => {
        this.populateForm(customer);
      });
    }
  }

  closeModal() {
    this.formSubmit.emit(null); 
  }

  initForm(): void {
    this.form = this.fb.group({
      name: ["", Validators.required],
      surname: ["", Validators.required],
      dateOfBirth: [""],
      phoneNumber: [""],
      imbg: [""],
      address: [""],
      profession: [""],
      passport: [""],
      email: ["", [Validators.email]],
    });
  }

  populateForm(customer: Customer) {
    const dateOfBirth = customer.dateOfBirth ? this.formatDateToDDMMYYYY(new Date(customer.dateOfBirth)) : "";
    this.form.patchValue({
      name: customer.name || "",
      surname: customer.surname || "",
      dateOfBirth: dateOfBirth,
      phoneNumber: customer.phoneNumber || "",
      imbg: customer.imbg || "",
      address: customer.address || "",
      profession: customer.profession || "",
      passport: customer.passport || "",
      email: customer.email || "",
    });
  }
  formatDateToDDMMYYYY(date: Date): string {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.formSubmit.emit(this.form.value);
  }
  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];
}
