import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Organizer } from "src/app/domain/organizers/models/organizers";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-organizer-form",
  templateUrl: "./organizer-form.component.html",
})
export class OrganizerFormComponent implements OnInit {
  @Input() editMode = false;
  @Input() organizerData: Organizer | null = null; 
  @Input() isLoading = false;
  @Output() formSubmit = new EventEmitter<any>();
  form!: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
    if (this.organizerData) {
      this.populateForm(this.organizerData);
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: ["", Validators.required],
      firstName: [""],
      lastName: [""],
      phone: [""],
      licence: [""],
      email: ["", [Validators.email]],
      address: [""],
    });
  }

  populateForm(data: Organizer) {
    if (!data) return; 
    this.form.patchValue({
      name: data.name,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      licence: data.licence,
      email: data.email,
      address: data.address,
    });
  }

  submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.formSubmit.emit(this.form.value);
  }

  closeModal() {
    this.formSubmit.emit(null);
  }
}