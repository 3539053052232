<div class="main-section relative font-nunito text-sm font-normal antialiased" [ngClass]="[store.sidebar ? 'toggle-sidebar' : '', store.menu, store.layout, store.rtlClass]">
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="relative">
    <!-- sidebar menu overlay -->
    <div class="fixed inset-0 z-50 bg-[black]/60 lg:hidden" [ngClass]="{ hidden: !store.sidebar }" (click)="storeData.dispatch({ type: 'toggleSidebar' })"></div>

    <!-- screen loader -->
    <div *ngIf="store.isShowMainLoader" class="screen_loader animate__animated fixed inset-0 z-[60] grid place-content-center bg-[#fafafa] dark:bg-[#060818]">
      <icon-app-loader></icon-app-loader>
    </div>

    <div *ngIf="showTopButton" class="fixed bottom-6 z-50 ltr:right-6 rtl:left-6">
      <button type="button" class="btn btn-outline-primary animate-pulse rounded-full bg-[#fafafa] p-2 dark:bg-[#060818] dark:hover:bg-primary" (click)="goToTop()">
        <icon-arrow-top></icon-arrow-top>
      </button>
    </div>

    <div class="main-container min-h-screen text-black dark:text-white-dark" [ngClass]="[store.navbar]">
      <!--  BEGIN SIDEBAR  -->
      <app-sidebar></app-sidebar>
      <!--  END SIDEBAR  -->

      <div class="main-content flex min-h-screen flex-col">
        <!--  BEGIN TOP NAVBAR  -->
        <app-header></app-header>
        <!--  END TOP NAVBAR  -->

        <!--  BEGIN CONTENT AREA  -->
        <div class="animation content-area p-5 print:p-0">
          <div class="panel mb-3 flex items-center overflow-x-auto whitespace-nowrap p-5 text-primary print:hidden">
            <app-breadcrumbs></app-breadcrumbs>
          </div>
          <router-outlet></router-outlet>
        </div>
        <!--  END CONTENT AREA  -->

        <!-- BEGIN FOOTER -->
        <app-footer class="mt-auto"></app-footer>
        <!-- END FOOTER -->
      </div>
    </div>
  </div>
</div>
