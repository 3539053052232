<div class="col-span-1 sm:col-span-1" *ngIf="showDocumentPanel">
  <div class="panel relative rounded-lg rounded-md border border-white-dark/20 bg-white p-4 shadow-md dark:bg-gray-900" id="payment">
    <div class="d-flex justify-content-between align-items-center">
      <div class=" flex flex-wrap items-center justify-between">
      <div class="flex-2" >
        <img [src]="'http://' + paymentDocument?.officeBranch?.logoImage?.url" alt="" class="w-15 h-20" />
      </div>
      <div class="space-y-1 text-white-dark">
        <div class="w-full">
          <div class="space-y-1 text-white-dark">
            <div class="flex flex-wrap justify-end">
              <span class="mr-2">{{ paymentDocument?.officeBranch?.fullAddress }} </span>
              <icon-home />
            </div>
            <div class="flex flex-wrap justify-end">
              <span class="mr-2">{{ paymentDocument?.officeBranch?.phoneNumber }} </span>
              <icon-phone />
            </div>
            <div class="flex flex-wrap justify-end">
              <span class="mr-2">{{ paymentDocument?.officeBranch?.email }} </span>
              <icon-mail />
            </div>
            <div class="flex flex-wrap justify-end">
              <span class="mr-2">https://www.{{ paymentDocument?.officeBranch?.siteURL }} </span>
              <icon-chrome />
            </div>
            <div class="flex flex-wrap justify-end">
              <span class="mr-2">{{ "giro-account" | translate }}: {{ paymentDocument?.officeBranch?.bankAccount }},PIB: {{ paymentDocument?.officeBranch?.pib }} </span>
              <icon-zip-file />
            </div>
          </div>
        </div>
      </div>
  
      </div>
    </div>
    <div class="mt-4 flex">
      <div>
        <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
          <label for="hotel" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.travelTitle" | translate }}:</label>
          <div class="overflow-hidden text-ellipsis">{{ paymentDocument?.travelTitle }}</div>
        </div>
        <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
          <label for="checkin" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.customerFullName" | translate }}:</label>
          <div class="overflow-hidden text-ellipsis">{{ paymentDocument?.customerFullName }}</div>
        </div>
        <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
          <label for="checkin" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.email" | translate }}:</label>
          <div class="overflow-hidden text-ellipsis">{{ paymentDocument?.customerEmail }}</div>
        </div>
        <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
          <label for="number" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.previouspayments" | translate }}:</label>
          <div class="flex flex-col w-full lg:w-[250px]" name="invoiceNo">
            <div *ngIf="paymentDocument && paymentDocument.paymentDetails">
              <div *ngFor="let payment of parsePaymentDetails(paymentDocument.paymentDetails)" class="flex justify-between">
                <div class="flex-1 overflow-hidden text-ellipsis">{{ payment.PayedAt }} -- {{ payment.Amount | currency: "EUR" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
          <label for="checkout" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.totalPrice" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.totalPrice | currency : "EUR" }}</div>
        </div>
        <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
          <label for="checkout" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.avans" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.avans | number : "1.2-2" | currency : "EUR" }}</div>
        </div>
        <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
          <label for="checkout" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.currentPayment" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.currentPayment | currency : "EUR" }}</div>
        </div>
        <!-- <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
          <label for="checkout" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.paymentType" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.paymentType }}</div>
        </div> -->
        <hr class="my-2 border-[#e0e6ed] dark:border-[#1b2e4b]" />
        <div class="mt-2 flex flex-col lg:flex-row lg:items-center text-red-500">
          <label for="checkout" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.toBePaid" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.toBePaid | currency : "EUR" }}</div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="mx-auto rounded-lg">
        <div class="flex justify-between">
          <div>
            <p>
              <strong>{{ "paymentdetails.dateOfPayment" | translate }}:</strong> {{ getCurrentDate() | date : "dd.MM.yyyy" }}
            </p>
            <p>
              <strong>{{ "paymentdetails.agent" | translate }}:</strong> {{paymentDocument?.createdByFullName}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
