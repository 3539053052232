import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { User } from "src/app/auth/model/user";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericResponse } from "src/app/shared/models/generics";
import { BaseHttpService } from "src/app/service/base.http.service";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";

@Injectable({
  providedIn: "root",
})
export class TableService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getItemsWithHeaders<T>(
    url: string,
    pageNumber: number,
    pageSize: number,
    filterType: string,
    from: Date,
    to: Date | undefined,
    sortColumn?: string | undefined,
    sortDirection?: string | undefined,
    search?: string
  ): Observable<GenericResponse<T>> {
    let params = new HttpParams()
      .set("filterType", filterType)
      .set("pageSize", pageSize.toString())
      .set("pageNumber", pageNumber.toString())
      .set("from", DateTimeFormatter.formatDateToString(from))
      .set("to", DateTimeFormatter.formatDateToString(to ?? null));

    if (sortDirection) {
      params = params.set("sortType", sortDirection);
    }

    if (sortColumn) {
      params = params.set("sortProperty", sortColumn);
    }

    if (search) {
      params = params.set("searchText", search);
    }

    return this.http.get<T[]>(`${this.apiUrl}/api/${url}`, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<T> = {
          body: response.body ?? [],
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }

  getTransfers() {
    return this.http.get<any[]>(`${this.apiUrl}/api/transfers`);
  }

  updateUser(id: number, data: any) {
    return this.http.put<User>(`${this.apiUrl}/api/users/${id}`, data);
  }
}
