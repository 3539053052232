import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  moduleId: module.id,
  templateUrl: "./error404.html",
})
export class Error404Component {
  constructor(public router: Router, public translate: TranslateService) {}
}
