<div class="relative flex min-h-screen items-center justify-center overflow-hidden">
  <div
    class="w-2/3 px-6 py-16 text-center font-semibold before:container before:absolute before:left-1/2 before:aspect-square before:-translate-x-1/2 before:rounded-full before:bg-[linear-gradient(180deg,#e3342f_0%,rgba(227,52,47,0)_50.73%)] before:opacity-10 md:py-20"
  >
    <div class="relative w-full">
      <img src="/assets/images/error/404.svg" alt="404" class="mx-auto w-full max-w-lg object-cover md:max-w-3xl" />
      <p class="mt-5 text-base dark:text-white">{{ '404.message' | translate }}</p>
      <a href="/dashboard/sales" class="btn btn-danger mx-auto !mt-7 w-max border-0 uppercase shadow-none">{{ '404.title' | translate }}</a>
    </div>
  </div>
</div>
