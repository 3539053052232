import * as moment from "moment";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Announcement } from "../../models/announcement";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Customer } from "src/app/domain/customers/models/customer";
import { VoucherService } from "src/app/domain/documents/voucher/services/voucher.service";
import { AnnouncementService } from "../../services/announcement.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-announcement-edit",
  templateUrl: "./announcement.edit.component.html",
})
export class AnnouncementEditComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public fb: FormBuilder,
    public router: Router,
    public voucherService: VoucherService,
    private annoucmentService: AnnouncementService,
    private translate: TranslateService
  ) {}
  travelId!: number;
  announcementid!: number;
  announcement!: Announcement;
  travel!: string;
  passangers!: Customer[];
  id!: number;
  form!: FormGroup;

  mask8 = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
  today: string = new Date().toLocaleDateString("sr-RS");
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.travelId = Number(params.get("id"));
      this.announcementid = Number(params.get("announcementid"));
      this.getVoucherData(this.travelId);
    });
  }
  getVoucherData(id: number) {
    this.annoucmentService.getAnnouncementsByTravel(id).subscribe((response) => {
      this.announcement = response;
      this.passangers = response.passengers;
      this.passangers = this.passangers.map((passanger: Customer) => {
        passanger.fullName = passanger.name + " " + passanger.surname;
        passanger.email = passanger.email || "";
        passanger.dateOfBirth = passanger.dateOfBirth ? moment(passanger.dateOfBirth).format("DD/MM/YYYY") : undefined;
        return passanger;
      });
      this.form = this.fb.group({
        agent: [this.announcement?.agent || ""],
        transport: [this.announcement?.transport || ""],
        hotelLabel: [this.announcement?.hotelLabel || ""],
        checkin: [moment(this.announcement?.checkin).format("DD/MM/YYYY").toString() || ""],
        checkout: [moment(this.announcement?.checkout).format("DD/MM/YYYY").toString() || ""],
        roomService: [this.announcement?.roomService || ""],
        service: [this.announcement?.service || ""],
        accommodation: [this.announcement?.accommodation || ""],
        start: [moment(this.announcement?.start).format("DD/MM/YYYY").toString() || ""],
        end: [moment(this.announcement?.end).format("DD/MM/YYYY").toString() || ""],
        days: [this.announcement?.days?.toString() || ""],
        nights: [this.announcement?.nights?.toString() || ""],
        email: [this.announcement?.email || ""],
      });
    });
  }

  save() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      this.form.markAllAsTouched();
      return;
    }
    const subscription = this.annoucmentService.updateAnnouncement(this.fetchVoucher(), this.announcementid, this.travelId).subscribe({
      next: () => {
        showMessage(PopupType.Success, this.translate.instant("popup.announcements_updated_successfully"));
        subscription.unsubscribe();
        this.router.navigate([`/dashboard/travels/draft/edit/${this.travelId}`], {
          queryParams: { activeTab: 3 },
        });
      },
      error: () => {
        showMessage(PopupType.Danger, this.translate.instant("popup.error_while_updating_announcement"));
      },
    });
  }

  convertToLocalDate(dateString: string): string {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("sr-RS", { day: "2-digit", month: "2-digit", year: "numeric" });
  }

  fetchVoucher() {
    this.passangers = this.passangers.map((passanger: any) => {
      passanger.name = passanger.fullName.split(" ")[0];
      passanger.surname = passanger.fullName.split(" ")[1];
      passanger.email = passanger.email || "";
      //passanger.dateOfBirth = passanger.dateOfBirth ? moment(passanger.dateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD") : undefined;
      return passanger;
    });
    const { agent, transport, hotelLabel, checkin, checkout, roomService, service } = this.form.value;
    const announcement = {
      agent,
      transport,
      hotelLabel,
      checkin: moment(checkin, "DD/MM/YYYY").format("YYYY-MM-DD"),
      checkout: moment(checkout, "DD/MM/YYYY").format("YYYY-MM-DD"),
      roomService,
      service,
      passengers: this.passangers,
      accommodation: this.form.value.accommodation,
      start: moment(this.form.value.start, "DD/MM/YYYY").format("YYYY-MM-DD"),
      end: moment(this.form.value.end, "DD/MM/YYYY").format("YYYY-MM-DD"),
      days: this.form.value.days,
      nights: this.form.value.nights,
      email: this.form.value.email,
    };
    return announcement;
  }
  cancelEdit() {
    this.router.navigate([`/dashboard/travels/draft/edit/${this.travelId}`], {
      queryParams: { activeTab: 3 },
    });
  }
}
