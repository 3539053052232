<div class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
  <app-file-upload [id]="planId"></app-file-upload>
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
</div>
<div *ngIf="ticket">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div class="text-xl font-bold">
            {{ "ticket_details.lead_passanger" | translate }}
          </div>
          <div class="text-xl">{{ ticket.customer.name }} {{ ticket.customer.surname }}</div>
          <div class="text-[15px] italic text-white-dark" style="font-style: italic">
            {{ ticket.customer.phoneNumber }}
          </div>
        </div>

        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div class="flex flex-col">
            <div>{{ "ticket_details.status" | translate }}:</div>
            <ng-container *ngIf="ticket.plan.status; else notPaid">
              <div class="badge mt-1 inline-flex w-auto self-start bg-success">
                {{ "ticket_details.paid" | translate }}
              </div>
            </ng-container>
            <ng-template #notPaid>
              <div class="badge mt-1 inline-flex w-auto self-start bg-slate-200 text-white">
                {{ "ticket_details.not_paid" | translate }}
              </div>
            </ng-template>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="ltr:mr-2 rtl:ml-2">{{ "ticket_details.active" | translate }}:</div>
            <ng-container *ngIf="ticket.plan.active; else notActive">
              <div class="badge mt-1 inline-flex w-auto self-start bg-success">
                {{ "ticket_details.active" | translate }}
              </div>
            </ng-container>
            <ng-template #notActive>
              <div class="badge mt-1 inline-flex w-auto self-start bg-slate-200 text-white">
                {{ "ticket_details.deleted" | translate }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 grid grid-cols-2 sm:col-span-2 sm:grid-cols-4">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "travel_details_label" | translate }}
            </div>
          </div>

          <div class="col-span-1 flex flex-col sm:col-span-2">
            <div>{{ "ticket_details.departure_from" | translate }} :</div>
            <div>{{ ticket.departure.description }}</div>
          </div>
          <div class="col-span-1 flex flex-col sm:col-span-2">
            <div>{{ "ticket_details.destination" | translate }} :</div>
            <div>{{ ticket.destination.description }}</div>
          </div>
        </div>
        <div class="col-span-2 grid grid-cols-2 sm:col-span-2 sm:grid-cols-4">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "tickets_info.reservation_number" | translate }}:
              {{ ticket.reservationNumber ? ticket.reservationNumber : "/" }}
            </div>
          </div>

          <div class="col-span-1 flex flex-col sm:col-span-2">
            <div>{{ "ticket_details.company" | translate }}:</div>
            <div>{{ ticket.company.name }}</div>
          </div>
          <div class="col-span-1 flex flex-col sm:col-span-2">
            <div>{{ "ticket_details.ticket_type" | translate }} :</div>
            <div *ngIf="ticket.oneWay">
              {{ "ticket_details.one_way" | translate }}
            </div>
            <div *ngIf="!ticket.oneWay">
              {{ "ticket_details.round_trip" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "paymentssendemail.payments" | translate }}
          </div>
        </div>
        <app-sale-details class="col-span-2 sm:col-span-4" [id]="planId" [hideStatus]="true"></app-sale-details>
      </div>
    </div>

    <div class="my-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4" *ngIf="passangers.length > 0">
      <div class="text-[20px] text-dark">{{ "ticket_details.all_passengers" | translate }}:</div>
      <div class="grid grid-cols-1 justify-center sm:grid-cols-1">
        <ng-container *ngFor="let item of passangers; index as i">
          <div
            class="flex grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-4"
          >
            <div class="user-profile grid-cols-1 justify-self-center sm:grid-cols-1 sm:justify-self-start">
              <img [src]="item.imageUrl ? item.imageUrl : '/assets/images/auth/user-icon.png'" alt="" class="h-8 w-8 rounded-md object-cover" />
            </div>

            <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ item.name }} {{ item.surname }}</div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "ticket_details.phone_number" | translate }}:
              {{ item.phoneNumber ? item.phoneNumber : "N/A" }}
            </div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "ticket_details.date_of_birth" | translate }}:
              {{ item.dateOfBirth ? (item.dateOfBirth | date : "dd.MM.yyyy") : "N/A" }}
            </div>

            <ng-template #backUp> </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
