<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
  <button class="btn btn-success gap-2" (click)="saveContract()" [disabled]="isLoading">
    <icon-save />
    {{ "contract.save" | translate }}
  </button>
  <a type="button" class="btn btn-info cursor-pointer gap-2" (click)="cancelEdit()">
    <icon-save />
    <!-- TODO:: translate contract.cancel -->
    {{ "contract.cancel" | translate }}
  </a>
</div>

<div class="panel" *ngIf="contract">
  <div class="grid text-center text-xl font-bold">
    <p>{{ "contract.travel_contract" | translate }}</p>
        <!-- TODO:: translate  -->
    <p>br. {{ contract.uniqueId }} od {{ contract.createDate | date : "dd.MM.yyyy" }}</p>
  </div>

  <div class="mt-4 grid grid-cols-1 gap-1 sm:grid-cols-1">
    <div class="col-span-2 flex flex-col sm:col-span-1">
      <div class="-dark text-white"></div>
      <div>{{ "contract.passangers_information" | translate }}</div>
    </div>
    <table class="table-bordered mb-4 table">
      <thead class="bg-gray-100">
        <tr>
          <th rowspan="4">{{ "vaucher.guests" | translate }}</th>
          <th>{{ "contract.name_and_surname" | translate }}</th>
          <!-- <th>{{ "contract.date_of_birth" | translate }}</th> -->
          <th>{{ "contract.email" | translate }}</th>
          <th>{{ "contract.address" | translate }}</th>
          <th>{{ "contract.contact" | translate }}</th>
        </tr>
      </thead>
      <tbody class="border-1">
        <ng-container *ngFor="let passenger of passangers; let i = index">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>
              <input class="form-input border-0" type="text" [(ngModel)]="passenger.fullName" name="fullName{{ i }}" />
            </td>
            <!-- <td>
              <input class="form-input border-0" type="text" placeholder="__/__/____" [textMask]="{ mask: mask8 }" [(ngModel)]="passenger.dateOfBirth" name="dateOfBirth{{ i }}" />
            </td> -->
            <td>
              <input class="form-input border-0" type="text" [(ngModel)]="passenger.email" name="address{{ i }}" />
            </td>
            <td>
              <input class="form-input border-0" type="text" [(ngModel)]="passenger.address" name="address{{ i }}" />
            </td>
            <td>
              <input class="form-input border-0" type="text" [(ngModel)]="passenger.phoneNumber" name="phoneNumber{{ i }}" />
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <form [formGroup]="form" (ngSubmit)="saveContract()">
      <div class="col-span-2 flex flex-col sm:col-span-1">
        <div class="-dark text-white"></div>
        <div>{{ "contract.traveller_information" | translate }}</div>
      </div>
      <div class="mb-6 p-4 border rounded-lg bg-gray-50">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label for="destination" class="block text-sm font-medium text-gray-700">{{ "contract.destination" | translate }}</label>
            <input id="destination" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="destination" />
          </div>
          <div>
            <label for="hotel" class="block text-sm font-medium text-gray-700">{{ "contract.hotel" | translate }}</label>
            <input id="hotel" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="hotel" />
          </div>
          <div>
            <label for="checkIn" class="block text-sm font-medium text-gray-700">{{ "contract.check_in" | translate }}</label>
            <input id="checkIn" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" placeholder="__/__/____" [textMask]="{ mask: mask8 }" formControlName="checkIn" />
          </div>
          <div>
            <label for="checkOut" class="block text-sm font-medium text-gray-700">{{ "contract.check_out" | translate }}</label>
            <input id="checkOut" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" placeholder="__/__/____" [textMask]="{ mask: mask8 }" formControlName="checkOut" />
          </div>
          <div>
            <label for="startDate" class="block text-sm font-medium text-gray-700">{{ "contract.start_date" | translate }}</label>
            <input id="startDate" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" placeholder="__/__/____" [textMask]="{ mask: mask8 }" formControlName="startDate" />
          </div>
          <div>
            <label for="endDate" class="block text-sm font-medium text-gray-700">{{ "contract.end_date" | translate }}</label>
            <input id="endDate" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" placeholder="__/__/____" [textMask]="{ mask: mask8 }" formControlName="endDate" />
          </div>
          <div>
            <label for="service" class="block text-sm font-medium text-gray-700">{{ "contract.service" | translate }}</label>
            <input id="service" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="service" />
          </div>
          <div>
            <label for="transportation" class="block text-sm font-medium text-gray-700">{{ "contract.transportation" | translate }}</label>
            <input id="transportation" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="transportation" />
          </div>
          <div>
            <label for="days" class="block text-sm font-medium text-gray-700">{{ "contract.days" | translate }}</label>
            <input id="days" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="days" />
          </div>
          <div>
            <label for="nights" class="block text-sm font-medium text-gray-700">{{ "contract.nights" | translate }}</label>
            <input id="nights" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="nights" />
          </div>
          <div>
            <label for="rooms" class="block text-sm font-medium text-gray-700">{{ "contract.rooms" | translate }}</label>
            <input id="rooms" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="rooms" />
          </div>
        </div>
      </div>
      
      <div class="col-span-2 flex flex-col sm:col-span-1">
        <div class="-dark text-white"></div>
        <div>{{ "contract.trip_invoice" | translate }}</div>
      </div>
      <table class="table-responsive table-bordered mb-5 w-full bg-gray-50">
        <tbody>
          <tr class="bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300">1</td>
            <td class="border border-gray-300">{{ "contract.total_in_euro" | translate }}</td>
            <td class="border border-gray-300">
              <input class="form-input border-0" type="text" formControlName="totalInEuro" />
            </td>
          </tr>
          <tr class="bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300">2</td>
            <td class="border border-gray-300">{{ "contract.total_in_din" | translate }}</td>
            <td class="border border-gray-300">
              <input class="form-input border-0" type="text" formControlName="totalInDinars" />
            </td>
          </tr>
        </tbody>
      </table>
    <div class="mb-6 p-4 border rounded-lg bg-gray-50">
      <div class="text-lg font-semibold mb-4">{{ "contract.organizer" | translate }}</div>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <label for="organizerName" class="block text-sm font-medium text-gray-700">{{ "organizer.name" | translate }}</label>
          <input id="organizerName" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="organizerName" />
        </div>
        <div>
          <label for="organizerPhone" class="block text-sm font-medium text-gray-700">{{ "organizer.phone" | translate }}</label>
          <input id="organizerPhone" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="organizerPhone" />
        </div>
        <div>
          <label for="organizerLicence" class="block text-sm font-medium text-gray-700">{{ "organizer.licence" | translate }}</label>
          <input id="organizerLicence" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="organizerLicence" />
        </div>
        <div>
          <label for="responsiblePerson" class="block text-sm font-medium text-gray-700">{{ "organizer.fullName" | translate }}</label>
          <input id="responsiblePerson" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="responsiblePerson" />
        </div>
        <div>
          <label for="responsiblePersonEmail" class="block text-sm font-medium text-gray-700">{{ "organizer.email" | translate }}</label>
          <input id="responsiblePersonEmail" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="email" formControlName="responsiblePersonEmail" />
        </div>
        <div>
          <label for="officeBranch" class="block text-sm font-medium text-gray-700">{{ "contract.office_branch" | translate }}</label>
          <input id="officeBranch" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="officeBranch" />
        </div>
        <div>
          <label for="officeAddress" class="block text-sm font-medium text-gray-700">{{ "contract.address" | translate }}</label>
          <input id="officeAddress" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="officeAddress" />
        </div>
        <div>
          <label for="officeLicence" class="block text-sm font-medium text-gray-700">{{ "contract.license_number" | translate }}</label>
          <input id="officeLicence" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="officeLicence" />
        </div>
      </div>
    </div>
  </form>
  </div>
</div>
