import { Passanger } from "./../../tickets/models/ticket";
import { Subscription } from "rxjs";
import { FormGroup } from "@angular/forms";
import { SaleBase } from "../../sales/models/sale";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Transfer } from "../../transfers/models/transfer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { GroupTravelsService } from "../services/group-travels.service";
import { Customer, PassangerTypes } from "../../customers/models/customer";
import { SaleCreateComponent } from "../../sales/sale.create/sale.create.component";
import { PassangersListComponent } from "../../passangers/passangers.list/passangers.list.component";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from "@angular/core";
import { GroupTravelPassanger, GroupTravelPlanDetails, GroupTravelPlanToAddWithEntities } from "../models/group-travel";
import * as moment from "moment";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";

@Component({
  selector: "app-group-travel-plan-edit",
  templateUrl: "./group-travel-plan-edit.component.html",
})
export class GroupTravelPlanEditComponent implements OnInit, OnDestroy {
  @Input() passangerId!: number;
  @Output() updated = new EventEmitter<any>();

  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("sale") sales!: SaleCreateComponent;
  @ViewChild("modal") modal!: ModalComponent;
  activeTab = 1;
  passengers!: Customer[];
  public sale!: SaleBase;
  id!: number;
  transfer!: Transfer;
  isForwardDirection!: boolean;
  customer!: Customer[];
  public mainCustomer!: Customer;
  public planId!: number;
  public customerId!: number;
  isLoading = false;

  currentPlan: any;
  groupTravelPlan!: GroupTravelPlanDetails;
  editForm!: FormGroup;
  groupTravelId!: number;
  private routeSubscribtion!: Subscription;

  constructor(private groupTravelsService: GroupTravelsService, private translate: TranslateService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.routeSubscribtion = this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.fetchEntities();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscribtion.unsubscribe();
  }

  fetchEntities() {
    this.groupTravelsService.getGroupTravelPlans(this.id).subscribe({
      next: ({ customer, plan, groupTravelPassangers, groupTravelId, planId }) => {
        this.groupTravelId = groupTravelId;
        this.sale = plan;
        this.planId = planId;
        this.sales.initForm(this.sale);

        const customerList = groupTravelPassangers.filter((p) => p.customer && p.customer.id !== customer.id).map((p) => ({ ...p.customer, lifeStage: p.lifeStage, isMainCustomer: p.isMainCustomer }));
        this.passangerList.push(customerList);

        this.mainCustomer = {
          ...customer,
          lifeStage: PassangerTypes.adults,
          guid: crypto.randomUUID(),
          imageUrl: "/assets/images/man.svg",
          mainCustomer: true,
          isMainCustomer: groupTravelPassangers.find((p) => p.customerId === customer.id)?.isMainCustomer,
        } as Customer;

        if (this.passangerList) {
          this.customerId = customer.id;
          this.passangerList.setMainCustomer(this.mainCustomer);
        }
      },
      error: (error) => console.error("Error fetching group travel plans:", error),
    });
  }

  proceedToNextStage(nextStep: number | undefined = undefined) {
    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  updateGroupTravelPassangers() {
    if (this.sales.checkIfValid() && this.passangerList.checkValidity()) {
      this.passengers = this.passangerList.getPassangers();
      this.sale = this.sales.getSale();
      this.isLoading = true;
      const updateSub = this.groupTravelsService.updateGroupTravelPassanger(this.id, this.updateReservationObject()).subscribe({
        next: () => {
          this.isLoading = false;
          showMessage(PopupType.Success, this.translate.instant("group-travels_info.updated_passengers_successfully"));
          updateSub.unsubscribe();
          this.router.navigate(["/dashboard/group-travels/" + this.groupTravelId]);
        },
        error: () => {
          this.isLoading = false;
          updateSub.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("group-travels_info.error_while_updating_passengers"));
        },
      });
    }
  }
  updateReservationObject(): GroupTravelPlanToAddWithEntities {
    return {
      planId: this.planId,
      customerId: this.customerId,
      groupTravelId: this.groupTravelId,
      plan: {
        ...this.sales.getSale(),
        dueDate: moment(this.sale.dueDate, "DD.MM.YYYY").toISOString(),
        customerId: this.customerId,
      },
      passangers: this.passangerList.getPassangers().map((p) => {
        return {
          customerId: p.id,
          lifeStage: p.lifeStage,
          isMainCustomer: p.isMainCustomer,
        } as GroupTravelPassanger;
      }),
    };
  }
}
