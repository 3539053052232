import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { TravelDestination } from "../../travels/models/travel-destination";
import { GroupTravel, GroupTravelPlan, GroupTravelPlanToAddWithEntities } from "../models/group-travel";

@Injectable({
  providedIn: "root",
})
export class GroupTravelsService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getGroupTravelData(id: number) {
    return this.http.get<GroupTravel>(`${this.apiUrl}/api/GroupTravels/${id}`);
  }

  getGroupTravelPlansForQuickView(id: number) {
    return this.http.get<GroupTravelPlan>(`${this.apiUrl}/api/GroupTravelPlans/${id}/quickview`);
  }

  getGroupTravelPlans(id: number) {
    return this.http.get<GroupTravelPlan>(`${this.apiUrl}/api/GroupTravelPlans/${id}`);
  }

  createGroupTravel(groupTravel: GroupTravel) {
    return this.http.post<GroupTravel>(`${this.apiUrl}/api/GroupTravels`, groupTravel);
  }

  updateGroupTravel(id: number, groupTravel: GroupTravel) {
    return this.http.put<GroupTravel>(`${this.apiUrl}/api/GroupTravels/${id}`, groupTravel);
  }

  createGroupTravelClone(id: number) {
    return this.http.post<GroupTravel>(`${this.apiUrl}/api/GroupTravels/clone/${id}`, {});
  }

  addGroupTravelPassanger(data: any) {
    return this.http.post<any>(`${this.apiUrl}/api/GroupTravelPlans/passangers-plan`, data);
  }
  updateGroupTravelPassanger(planId: number, data: GroupTravelPlanToAddWithEntities) {
    return this.http.put<any>(`${this.apiUrl}/api/GroupTravelPlans/plan/${planId}`, data);
  }

  getGroupTravels(): Observable<GroupTravel[]> {
    return this.http.get<GroupTravel[]>(`${this.apiUrl}/api/GroupTravels`);
  }
  deleteGroupTravel(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/GroupTravels/${id}`, { responseType: "text" });
  }
  getGroupTravelDestinations(id: number) {
    return this.http.get<any>(`${this.apiUrl}/api/GroupTravelDestinations/${id}`);
  }
  updateTravelDestination(id: number, travelDestination: TravelDestination): Observable<TravelDestination> {
    return this.http.put<TravelDestination>(`${this.apiUrl}/api/GroupTravelDestinations/${id}`, travelDestination);
  }
  deleteGroupTravelDestination(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/GroupTravelDestinations/${id}`, { responseType: "text" });
  }
  createTravelDestination(travelDestination: TravelDestination): Observable<TravelDestination> {
    return this.http.post<TravelDestination>(`${this.apiUrl}/api/GroupTravelDestinations`, travelDestination);
  }
}
