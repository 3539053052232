<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <app-file-upload [id]="planid"></app-file-upload>
   <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history> 
</div>
<div *ngIf="otherService">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div>
        <div class="col-span-2 flex flex-col sm:col-span-1">
          <div class="text-white-dark">
            {{ "otherservices.name" | translate }}
          </div>
          <div class="text-xl">{{ otherService.plan.customer.name }} {{ otherService.plan.customer.surname }}</div>
        </div>
      </div>

      <div class="col-span-2 flex sm:col-span-3">
        <div class="flex place-content-center items-center self-center">
          <div class="ltr:mr-2 rtl:ml-2">{{ "otherservices.active" | translate }}:</div>
          <ng-container *ngIf="otherService.plan.active !== undefined && otherService.plan.active !== null; else notActive">
            <div class="badge bg-success">
              {{ "otherservices.active" | translate }}
            </div>
          </ng-container>
          <ng-template #notActive>
            <div class="badge bg-slate-200 text-white">
              {{ "otherservices.deleted" | translate }}
            </div>
          </ng-template>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.email" | translate }} :</div>
          <div>{{ otherService.plan.customer.email }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.date_of_birth" | translate }} :</div>
          <div>{{ otherService.plan.customer.dateOfBirth | date : "dd.MM.yyyy" }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.phone_number" | translate }} :</div>
          <div>{{ otherService.plan.customer.phoneNumber }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.address" | translate }} :</div>
          <div>{{ otherService.plan.customer.address }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.profession" | translate }} :</div>
          <div>{{ otherService.plan.customer.profession }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.service_title" | translate }} :</div>
          <div>{{ otherService.service.title }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.created_at" | translate }}:</div>
          <div>{{ otherService.plan.createdAt | date : "dd.MM.yyyy" }}</div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.updated_at" | translate }}:</div>
          <div>{{ otherService.plan.updatedAt | date : "dd.MM.yyyy" }}</div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.bruto" | translate }}:</div>
          <div>{{ otherService.plan.bruto | currency : "EUR" }}</div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.neto" | translate }}:</div>
          <div>{{ otherService.plan.neto | currency : "EUR" }}</div>
        </div>
      </div>
      <div class="col-span-2 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.avans" | translate }}:</div>
          <div>{{ otherService.plan.avans | currency : "EUR" }}</div>
        </div>
      </div>
      <div class="col-span-2 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.paymentType" | translate }}:</div>
          <div>{{ otherService.plan.paymentType.title }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
