<div class="space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex w-full flex-col gap-5 md:items-center">
      <div class="mb-5 w-full">
        <div class="mt-3 flex w-full flex-wrap gap-2">
          <a
            href="javascript:;"
            class="-mb-[1px] flex items-center rounded p-3.5 py-2 !outline-none transition duration-300 hover:bg-warning hover:text-white"
            [ngClass]="{ 'bg-warning text-white': tab4.toLowerCase() === 'workers' }"
            (click)="tab4 = 'workers'"
          >
            <icon-menu-workers></icon-menu-workers>
            {{ "workers" | translate }}
          </a>

          <a
            href="javascript:;"
            class="-mb-[1px] flex items-center rounded p-3.5 py-2 !outline-none transition duration-300 hover:bg-warning hover:text-white"
            [ngClass]="{ 'bg-warning text-white': tab4.toLowerCase() === 'offices' }"
            (click)="tab4 = 'offices'"
            *appHasRole="['Admin']"
          >
            <icon-company></icon-company>
            {{ "offices" | translate }}
          </a>
        </div>
        <div class="flex-1 pt-5 text-sm">
          <div *ngIf="tab4.toLowerCase() === 'workers'">
            <app-workers-table> </app-workers-table>
          </div>
          <ng-container *ngIf="tab4.toLowerCase() === 'offices'">
            <div *appHasRole="['Admin']">
              <app-office-branch-table></app-office-branch-table>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
