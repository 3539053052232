import * as moment from "moment";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { Sale, SaleBase } from "../models/sale";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { SalesService } from "../services/sales.service";
import { toggleAnimation } from "src/app/shared/animations";
import { PicklistType } from "src/app/shared/enums/picklist-types";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import { brutoLessThanNetoValidator } from "src/app/shared/validators/sale.validator";
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from "@angular/core";

@Component({
  selector: "app-sale-create",
  templateUrl: "./sale.create.component.html",
  animations: [toggleAnimation],
})
export class SaleCreateComponent implements OnInit, OnDestroy {
  public netoFieldType = "password";
  public form!: FormGroup;
  public isSubmitForm = false;
  public formattedDate!: string;
  public withAvans = 0;
  public sale: SaleBase = {} as SaleBase;
  PicklistType = PicklistType;
  exchangeRate!: number;

  public paymentTypeSubscription: Subscription | undefined;

  @Input() initialSaleData: SaleBase = {} as SaleBase;

  @Output() submitSale: EventEmitter<Sale> = new EventEmitter<Sale>();

  constructor(public fb: FormBuilder, public store: Store<AppState>, public translate: TranslateService, public saleService: SalesService) {}
  ngOnInit(): void {
    this.saleService.getExchangeRate().subscribe((exchangeRate) => {
      this.exchangeRate = exchangeRate;
    });
    this.initForm();
  }

  initFormIntern(sale: SaleBase, withAvans = false) {
    const lastDayOfYear = moment().endOf("year").format("DD.MM.YYYY");

    const {
      status = withAvans,
      bruto = this.form ? this.form.controls["bruto"].value : null,
      neto = this.form ? this.form.controls["neto"].value : null,
      avans = this.form ? this.form.controls["avans"].value : null,
      dueDate = this.form ? this.form.controls["dueDate"].value : lastDayOfYear,
      paymentType = this.form ? this.form.controls["paymentType"].value : "",
    } = sale || {};

    const formattedDueDate = moment(dueDate, "DD.MM.YYYY").format("DD.MM.YYYY");

    this.form = this.fb.group(
      {
        bruto: [bruto, Validators.required],
        neto: [neto, Validators.required],
        avans: [avans],
        dueDate: [formattedDueDate, status ? { validators: [dateFormatValidator()], updateOn: "blur" } : []],
        paymentType: [paymentType, { validators: [Validators.required], updateOn: "blur" }],
        paymentTypeId: [paymentType?.id || null, Validators.required],
      },
      { validators: brutoLessThanNetoValidator }
    );
  
    this.paymentTypeSubscription = this.form.get("paymentType")?.valueChanges.subscribe((value) => {
      const paymentTypeIdControl = this.form.get("paymentTypeId");
      if (paymentTypeIdControl) {
        paymentTypeIdControl.setValue(value?.id || null);
        paymentTypeIdControl.updateValueAndValidity();
      }
    });

    const initialPaymentType = this.form.get("paymentType")?.value;
    if (initialPaymentType && initialPaymentType.id) {
      this.form.get("paymentTypeId")?.setValue(initialPaymentType.id);
    }
  }

  ngOnDestroy() {
    if (this.paymentTypeSubscription) {
      this.paymentTypeSubscription.unsubscribe();
    }
  }

  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  reveal() {
    this.netoFieldType = "text";
  }

  hide() {
    this.netoFieldType = "password";
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  getSale(): Sale {
    const formValue = this.form.getRawValue();
    return {
      ...this.sale,
      ...formValue,
      withAvans: this.withAvans,
    };
  }

  submitForm() {
    this.isSubmitForm = true;
    this.submitSale.emit(this.form.value);
  }

  setWithAvans(value: number) {
    this.withAvans = value;
    this.initFormIntern({} as SaleBase, Boolean(this.withAvans));
  }

  initForm(sale: SaleBase = {} as SaleBase) {
    this.sale = sale;
    this.withAvans = this.sale.avans != null && this.sale.avans > 0 ? 1 : 0;
    this.initFormIntern(sale, Boolean(this.withAvans));
  }
}
