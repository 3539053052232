import * as moment from "moment";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { Sale } from "../../sales/models/sale";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { DestinationStatistics } from "../models/destination";
import { MessagePortalComponent } from "src/app/portal/portal";
import { TableService } from "src/app/shared/services/table.service";
import { DestinationsService } from "./../services/destinations.service";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-destination",
  templateUrl: "./destination.component.html",
  animations: [toggleAnimation],
})
export class DestinationComponent extends BaseTableComponent<Sale> implements OnInit, OnDestroy {
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  private id!: number;
  protected destinationData!: DestinationStatistics;

  public sales: Sale[] = [];
  public chartData: any[] = [];
  public chartOptions: any;
  monthlyChartOptions: any;
  public exportApiUrl!: string;
  public baseUrl!: string;
  public additionalInfo!: string;
  private routeSubscriber!: Subscription;

  constructor(
    private destinationsService: DestinationsService,
    public translate: TranslateService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    private route: ActivatedRoute,
    private positioningService: PositioningService
  ) {
    super(store, tableService, "");
  }

  override ngOnInit() {
    this.routeSubscriber = this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.apiUrl = `Statistics/destination/${this.id}/paginated`;
      this.exportApiUrl = `api/Destinations/destination-plans/export/${this.id}`;
      super.ngOnInit();

      // Call fetchCompanyStatistics with the initial date values using moment
      this.fetchCompanyStatistics(moment(this.from).format("YYYY-MM-DD"), this.to ? moment(this.to).format("YYYY-MM-DD") : "");


      // Use the existing subscription to datepick$ from the parent class
      this.datepick$.subscribe((dateRange) => {
        const fromDate = moment(dateRange.from).format("YYYY-MM-DD");
        const toDate = dateRange.to ? moment(dateRange.to).format("YYYY-MM-DD") : "";
        this.fetchCompanyStatistics(fromDate, toDate);
      });
    });
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.routeSubscriber.unsubscribe();
  }
  override getColumns(): void {
    this.cols = [
      { field: "destination.description", title: this.translate.instant("destination_date.description"), hide: false } as DatatableColumn,
      { field: "organizer.name", title: this.translate.instant("destination_date.organizer"), hide: false } as DatatableColumn,
      { field: "hotel.name", title: this.translate.instant("destination_date.hotel"), hide: false } as DatatableColumn,
      { field: "departure.description", title: this.translate.instant("destination_date.departure"), hide: false } as DatatableColumn,
      { field: "number_of_passangers", title: this.translate.instant("destination_date.numberOfPassengers"), hide: false } as DatatableColumn,
      { field: "neto", title: this.translate.instant("destination_date.neto"), hide: false } as DatatableColumn,
      { field: "bruto", title: this.translate.instant("destination_date.bruto"), hide: false } as DatatableColumn,
      { field: "event_starts", title: this.translate.instant("destination_date.eventstart"), hide: false } as DatatableColumn,
      { field: "event_ends", title: this.translate.instant("destination_date.eventend"), hide: false } as DatatableColumn,
      { field: "status", title: this.translate.instant("destination_date.status"), hide: false } as DatatableColumn,
    ];
  }
  exportCols = [
    { field: "customerNameAndSurname", title: this.translate.instant("companyexport.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("companyexport.phoneNumber"), hide: false } as DatatableColumn,
    { field: "numberOfPersons", title: this.translate.instant("companyexport.numberOfPersons"), hide: false } as DatatableColumn,
    { field: "arrivalDate", title: this.translate.instant("companyexport.country"), hide: false } as DatatableColumn,
    { field: "departureDate", title: this.translate.instant("companyexport.departureDate"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("companyexport.bruto"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("companyexport.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "profit", title: this.translate.instant("companyexport.profit"), hide: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("companyexport.status"), hide: false } as DatatableColumn,
    { field: "dateOfPurchase", title: this.translate.instant("companyexport.purchaseDate"), hide: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("companyexport.user"), hide: false } as DatatableColumn,
  ];

  fetchCompanyStatistics(fromDate: string, toDate: string) {
    const destinationStatsSubscription = this.destinationsService.getDestinationsStatistics(this.id, fromDate, toDate).subscribe((response) => {
      this.destinationData = response;
      this.additionalInfo = [response.name, response.description].filter((value) => value).join(" ");
      this.getCharterStatistics();
      destinationStatsSubscription.unsubscribe();
    });
  }
  getCharterStatistics() {
    const visitsPerYear: { [key: string]: number } = {};

    this.destinationData.webChartData.forEach((data) => {
      const year = data.year.toString();
      visitsPerYear[year] = (visitsPerYear[year] || 0) + data.numberOfPlans;
    });

    this.chartData = Object.keys(visitsPerYear).map((year) => ({
      x: year,
      y: visitsPerYear[year],
    }));

    this.chartOptions = {
      series: [
        {
          name: this.translate.instant("destinationStatistics.traffic"),
          data: this.chartData,
          color: "#46b196",
        },
      ],
      chart: {
        height: 350,
        type: "bar",
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            const year = config.w.config.series[0].data[config.dataPointIndex].x;
            this.showMonthlyChart(year);
          },
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          barHeight: "75%",
          dataLabels: {
            position: "bottom",
          },
          colors: {
            ranges: [
              { from: 0, to: 5, color: "#FFB6C1" },
              { from: 6, to: 10, color: "#FF69B4" },
              { from: 11, to: 15, color: "#FF1493" },
              { from: 16, to: 20, color: "#C71585" },
              { from: 21, to: 25, color: "#DA70D6" },
              { from: 26, to: 30, color: "#BA55D3" },
              { from: 31, color: "#8A2BE2" },
            ],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
          },
        },
      },
      dataLabels: { enabled: false },
      xaxis: {
        type: "category",
        categories: this.chartData.map((item) => item.x),
      },

      yaxis: { title: { text: "" } },
      legend: { show: false },
    };
  }

  showMonthlyChart(year: string) {
    const monthlyDataForYear = this.destinationData.webChartData.filter((data) => data.year.toString() === year);

    const groupedData = new Map<string, number>();
    for (const data of monthlyDataForYear) {
      const date = new Date();
      date.setMonth(data.month - 1);
      const monthName = date.toLocaleString("default", { month: "long" });

      const currentCount = groupedData.get(monthName) || 0;
      groupedData.set(monthName, currentCount + data.numberOfPlans);
    }

    const monthlyChartData = Array.from(groupedData.entries()).map(([month, numberOfPlans]) => ({ x: month, y: numberOfPlans }));

    const monthlyChartOptions = {
      series: [
        {
          name: this.translate.instant("destinationStatistics.monthlytraffic"),
          data: monthlyChartData.map((item) => item.y),
          color: "#46b196",
        },
      ],
      chart: { height: 350, type: "line", toolbar: { show: false } },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%",
          endingShape: "rounded",
        },
      },
      colors: monthlyChartData.map(() => "#4CAF50"),
      dataLabels: { enabled: false },
      xaxis: { categories: monthlyChartData.map((item) => item.x), title: { text: "" } },
      yaxis: { title: { text: " " } },
      legend: { show: false },
    };

    this.monthlyChartOptions = monthlyChartOptions;
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
}
