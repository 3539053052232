import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { Transfer, TransferPlan, TransferPlanEdit, Vehicle } from "./../models/transfer";

@Injectable({
  providedIn: "root",
})
export class TransfersService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getTransfers(): Observable<Transfer[]> {
    return this.http.get<Transfer[]>(`${this.apiUrl}/api/transfers`);
  }
  getTransferData(id: number): Observable<Transfer> {
    return this.http.get<Transfer>(`${this.apiUrl}/api/transfers/${id}`);
  }

  getTransferPlan(id: number): Observable<TransferPlan> {
    return this.http.get<TransferPlan>(`${this.apiUrl}/api/transfers/plan/${id}`);
  }

  getTransfersPicklist() {
    return this.http.get<Vehicle[]>(`${this.apiUrl}/api/transfers/picklist`);
  }

  createTransfer(data: any) {
    return this.http.post<any>(`${this.apiUrl}/api/transfers`, data);
  }

  updateTransfer(id: any, data: any) {
    return this.http.put<any>(`${this.apiUrl}/api/transfers/${id}`, data);
  }

  createTransferClone(id: number) {
    return this.http.post<Transfer>(`${this.apiUrl}/api/transfers/clone/${id}`, {});
  }

  addPassangerToTransfer(transferId: number | undefined, data: any) {
    return this.http.post<any>(`${this.apiUrl}/api/transfers/plan/${transferId}`, data);
  }

  getTransferById(transferId: number) {
    return this.http.get<Transfer>(`${this.apiUrl}/api/Transfers/${transferId}`);
  }

  addTransferToTicket(id: number, data: any, type: string) {
    return this.http.post(`${this.apiUrl}/api/Transfers/${type}/${id}`, data);
  }

  deleteTransfer(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/transfers/${id}`, { responseType: "text" });
  }

  updateTransferPassanger(planId: number, data: TransferPlanEdit) {
    return this.http.put<any>(`${this.apiUrl}/api/Transfers/plan/${planId}`, data);
  }
}
