import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { OverallStatistics } from "src/app/domain/statisticsmoney/models/overallstatistics";

@Component({
  selector: "app-overall-statistics",
  templateUrl: "./overall-statistics.component.html",
})
export class OverallStatisticsComponent {
  @Input() overallStatistics: OverallStatistics | undefined;

  constructor(public translate: TranslateService) {}
}
