import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { catchError, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { PopupType } from "../shared/enums/popup-types";
import { showMessage } from "../shared/utils/toast.popup";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedRequest = request.clone({
      setHeaders: {
        "Access-Control-Allow-Origin": "*", // Replace with your allowed origin(s)
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      setParams: { observe: "response" },
    });
    return next.handle(modifiedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        const message = error.error || error.message;
        showMessage(PopupType.Danger, message.substring(0, 500));
        // Handle different types of errors
        if (error.status === 401) {
          // Unauthorized: Redirect to login page
          this.router.navigate(["/auth/login"]);
        } else if (error.status === 403) {
          // Forbidden: Handle access denied
          // You can customize this behavior based on your requirements
          console.error("Access denied:", error.message);
        } else {
          // Other errors: Log the error message
          console.error("HTTP Error:", error.message);
        }

        // Rethrow the error to propagate it further
        return throwError(error);
      })
    );
  }
}
