import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "./../../customers/models/customer";
import { Transfer, TransferPlan } from "../../transfers/models/transfer";

@Component({
  selector: "app-transfer-quick-view",
  templateUrl: "./transfer-quick-view.component.html",
  styleUrls: ["./transfer-quick-view.component.css"],
})
export class TransferQuickViewComponent {
  @Input() data!: Transfer;

  constructor(public translate: TranslateService) {}
  getReservationNumber(pass: Customer[]) {
    if (pass) {
      const arr = pass.map((passenger) => passenger.reservationNumber);
      return arr.join(", ");
    }
    return "";
  }

  calculateTotalPassangers(transferPlans: TransferPlan[]): number {
    if (!transferPlans || transferPlans.length === 0) {
      return 0;
    }

    let totalPassangers = 0;

    transferPlans.forEach((plan) => {
      if (plan.transferPassangers && plan.transferPassangers.length > 0) {
        totalPassangers += plan.transferPassangers.length;
      }
    });

    return totalPassangers;
  }
}
