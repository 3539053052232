import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { UserWorker } from "../models/worker";
import { User } from "src/app/auth/model/user";
import { ActivatedRoute } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { WorkersService } from "./../services/workers.service";
import { TableService } from "src/app/shared/services/table.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { WorkerFormComponent } from "../worker.form.component/worker.form.component";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

import { toggleAnimation } from "src/app/shared/animations";
@Component({
  selector: "app-workers-table",
  templateUrl: "./workers.table.component.html",
  animations: [toggleAnimation],
})
export class WorkersTableComponent extends BaseTableComponent<User> implements OnInit, OnDestroy {
  @ViewChild("deleteConfirmationModal")
  deleteConfirmationModal!: ModalComponent;
  @ViewChild("workerForm") workerForm!: WorkerFormComponent;
  @ViewChild("createWorkerModal") createWorkerModal!: ModalComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  public isEdit = false;
  public userIdForDelete!: number;
  public userId!: number | undefined;
  public worker!: UserWorker[];
  private routeSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private workersService: WorkersService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    public translate: TranslateService,
    private positioningService: PositioningService
  ) {
    super(store, tableService, `users/paginated`);
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.apiUrl = `users/paginated?type=${params["type"]}`;
      super.ngOnInit();
    });
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.routeSubscription.unsubscribe();
  }

  closeModal() {
    this.createWorkerModal.close();
    this.updatePaginationProps.next(this.getPaginationParams());
  }

  getColumns(): void {
    this.cols = [
      {field: "id",title: this.translate.instant("worker_info.id"),isUnique: true,hide: true,} as DatatableColumn,
      {field: "name",title: this.translate.instant("worker_info.name"),hide: false,} as DatatableColumn,
      {field: "email",title: this.translate.instant("worker_info.email"),hide: false,} as DatatableColumn,
      {field: "role",title: this.translate.instant("worker_info.role"),hide: false,} as DatatableColumn,
      {field: "officeBranch.name",title: this.translate.instant("worker_info.office_branch"),hide: false,} as DatatableColumn,
      {field: "createdAt",title: this.translate.instant("worker_info.created_at"),hide: false,} as DatatableColumn,
      {field: "actions",title: this.translate.instant("worker_info.actions"),hide: false,} as DatatableColumn,
    ];
  }


  openModalForCreating() {
    this.modalService.openWorkerAddModal().then((result) => {
      if (result) {
        this.updatePaginationProps.next(this.getPaginationParams());
        this.getColumns();
        showMessage(PopupType.Success, this.translate.instant("worker_data.worker_added_successfully"));
      }
    })
  }

  openModalForEditing(id: number) {
    this.modalService.openWorkerEditModal(id).then((result) => {
      if (result) {
        this.updatePaginationProps.next(this.getPaginationParams());
        this.getColumns();
        showMessage(PopupType.Success, this.translate.instant("worker_data.worker_changed_successfully"));
      }
    })
  }

  async deleteWorker(id: number) {
    const confirm = await this.modalService.confirm({
      title: this.translate.instant("worker"),
      message: this.translate.instant("worker.delete_worker"),
      confirmButtonText: this.translate.instant("yes"),
      cancelButtonText: this.translate.instant("no"),
    });
    if (confirm) {
      showMessage(PopupType.Success, this.translate.instant("popup.worker_deleted_successfully"));
    }
  }
}
