import { Picklist } from "../models/picklist";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, Validators } from "@angular/forms";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { SettingsService } from "../services/settings.service";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { slideDownUp } from "src/app/shared/animations";
import { ModalService } from "src/app/shared/services/modal.service";

@Component({
  selector: "app-settings-create",
  templateUrl: "./settings.create.component.html",
  animations: [slideDownUp],
})
export class SettingsCreateComponent implements OnInit {
  picklistType: Picklist[] = [];
  id: number | null = 1;

  @Output() updateActiveAccordion = new EventEmitter<number | undefined>();

  @Input() type = "room-type";
  @Input() title = "Room Type";
  @Input() accordion!: number | undefined;
  @Input() orderNumber!: number;
  @ViewChild("modal") modal!: ModalComponent;

  constructor(public settingsService: SettingsService, public fb: FormBuilder, public translate: TranslateService, private modalService: ModalService) {}

  modalTitle!: string;
  modalButton!: string;

  form = this.fb.group({
    title: ["", Validators.required],
    description: ["", Validators.required],
    delimeter: ["", Validators.required],
  });

  ngOnInit() {
    this.getPicklistType();
  }

  getPicklistType() {
    const getPicklistSub = this.settingsService.getPicklistType(this.type).subscribe({
      next: (res) => {
        getPicklistSub.unsubscribe();
        this.picklistType = res;
      },

      error: (error) => {
        getPicklistSub.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
    });
  }

  openModal() {
    this.modal.open();
    this.modalTitle = this.translate.instant("settings.add_new_item");
    this.modalButton = this.translate.instant("settings.add");
  }

  openModalForEditing(id: number) {
    this.id = id;
    const optionValue = this.picklistType.find((option) => option.id === id);

    this.modalTitle = this.translate.instant("settings.edit_item");
    this.modalButton = this.translate.instant("settings.update");

    this.form.patchValue({
      title: optionValue ? optionValue.title || "" : "",
      description: optionValue ? optionValue.description || "" : "",
      delimeter: optionValue ? optionValue.delimeter || "" : "",
    });

    this.modal.open();
  }

  setVisibleAccordion() {
    this.updateActiveAccordion.emit(this.accordion === this.orderNumber ? undefined : this.orderNumber);
  }

  fetchItem() {
    const { title, description } = this.form.value;
    const value = this.type.split("-").join(" ");
    const data = { title, description, delimeter: this.type, value: value };
    return data;
  }
  async deletePicklistOption(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSub = this.settingsService.deletePicklistItem(id).subscribe({
        next: () => {
          deleteSub.unsubscribe();
          this.getPicklistType();
          showMessage(PopupType.Success, this.translate.instant("settings.deleted_successfully"));
        },
        error: (error) => {
          deleteSub.unsubscribe();
          showMessage(PopupType.Danger, error.toString());
        },
      });
    }
  }

  addItem() {
    if (this.id === null) {
      const createSub = this.settingsService.addPicklistItem(this.fetchItem()).subscribe({
        next: () => {
          createSub.unsubscribe();
          this.getPicklistType();
          this.modal.close();
          showMessage(PopupType.Success, this.translate.instant("settings.added_successfully"));
        },
        error: (error) => {
          createSub.unsubscribe();
          showMessage(PopupType.Danger, error.toString());
        },
      });
    }
    if (this.id !== null) {
      const updateSub = this.settingsService.updatePicklistItem(this.fetchItem(), this.id).subscribe({
        next: () => {
          updateSub.unsubscribe();
          this.getPicklistType();
          this.modal.close();
          this.id = null;
          showMessage(PopupType.Success, this.translate.instant("settings.updated_successfully"));
        },
        error: (error) => {
          updateSub.unsubscribe();
          showMessage(PopupType.Danger, error.toString());
        },
      });
    }
  }
}
