import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Customer } from "../models/customer";
import { BaseHttpService } from "src/app/service/base.http.service";
import { GenericResponse } from "src/app/shared/models/generics";
import * as moment from "moment";
import { CustomerStats } from "../models/customer-stat";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { CustomerPlan } from "../models/customer-plan";

@Injectable({
  providedIn: "root",
})
export class CustomersService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getCustomers(pageNumber: number, pageSize: number, filterType: string): Observable<Customer[]> {
    const apiUrl = `${this.apiUrl}/api/customers/paginated`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("filterType", filterType);

    return this.http.get<Customer[]>(apiUrl, { params });
  }

  createCustomer(customer: Customer) {
    return this.http.post<Customer>(`${this.apiUrl}/api/customers`, customer);
  }
  createCustomerData(customer: any) {
    return this.http.post<Customer>(`${this.apiUrl}/api/customers`, customer);
  }

  editCustomer(id: number, customer: Customer) {
    const data = {
      ...customer,
      dateOfBirth: moment(customer.dateOfBirth).toISOString(),
      passportIssuingDate: moment(customer.passportIssuingDate).toISOString(),
      passportExpirationDate: moment(customer.passportExpirationDate).toISOString(),
    };
    return this.http.put<Customer>(`${this.apiUrl}/api/customers/${id}`, data);
  }
  getCustomerStats(id: number) {
    return this.http.get<CustomerStats>(`${this.apiUrl}/api/Statistics/customers/stats/${id}`);
  }
  getCustomerDetails(customerId: number): Observable<Customer> {
    return this.http.get<Customer>(`${this.apiUrl}/api/customers/${customerId}`);
  }
  mergeCustomer(rootId: number, listOfIds: number[]): Observable<any> {
    const url = `${this.apiUrl}/api/customers/merge`;
    const body = { rootId, listOfIds };
    return this.http.post(url, body, { responseType: "text" });
  }
  getCustomersAll() {
    return this.http.get<Customer[]>(`${this.apiUrl}/api/customers`);
  }
  updateCustomerData(id: number, data: any) {
    return this.http.put<Customer>(`${this.apiUrl}/api/customers/${id}`, data);
  }
}
