import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from '../models/company';

@Component({
	selector: 'app-company-form',
	templateUrl: './company-form.component.html'
})
export class CompanyFormComponent implements OnInit {
	@Input() editMode = false;
	@Input() entityData: Partial<Company> = {};
	@Input() isLoading = false;
	@Output() formSubmit = new EventEmitter<Company | null>();

	form!: FormGroup;

	constructor(private fb: FormBuilder) { }

	ngOnInit(): void {
		this.initForm();
		if (this.entityData && this.editMode) {
			this.populateForm(this.entityData);
		}
	}

	private initForm(): void {
		this.form = this.fb.group({
			name: [this.entityData.name || '', Validators.required],
			address: [this.entityData.address || ''],
			phoneNumber: [this.entityData.phoneNumber || ''],
			email: [this.entityData.email || '', [Validators.email]],
			representative: [this.entityData.representative || ''],
		});
	}

	private populateForm(data: Partial<Company>): void {
		this.form.patchValue({
			name: data.name,
			address: data.address,
			phoneNumber: data.phoneNumber,
			email: data.email,
			representative: data.representative,
		});
	}

	submitForm(): void {
		if (this.form.invalid) {
			this.form.markAllAsTouched();
			return;
		}
		this.formSubmit.emit(this.form.value);
	}

	closeModal(): void {
		this.formSubmit.emit(null);
	}
}