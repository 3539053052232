import { FormControl, FormGroup } from "@angular/forms";
import { Component, Input } from "@angular/core";
import { UtilService } from "src/app/shared/services/util.service";
import { EntityPicklistType } from "src/app/shared/enums/picklist-types";

@Component({
  selector: "app-destination-autocomplete",
  templateUrl: "./destination-autocomplete.component.html",
})
export class DestinationAutocompleteComponent {
  @Input() form!: FormGroup;
  EntityPicklistType = EntityPicklistType;
  @Input() formControlName!: string;
  isLoading = false;
  mockFormName: FormGroup;
  countrySelectorForm!: FormGroup;

  constructor(public utilService: UtilService) {
    this.mockFormName = new FormGroup({
      [this.formControlName]: new FormControl(""),
    });

    this.countrySelectorForm = new FormGroup({
      ["country"]: new FormControl(""),
    });
  }
  save() {
    this.isLoading = true;
    const country = this.countrySelectorForm.controls["country"].value;
    const countryId = country?.id;
    if (countryId !== undefined) {
      this.utilService.selectCountry(countryId);
      this.isLoading = false;
    }
  }
}
