import { Directive, ElementRef, HostListener, Input, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: "[appLimitCharacterInput]",
})
export class LimitCharacterInputDirective {
  @Input() appLimitCharacterInput!: number;
  private messageElement!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2, private translate: TranslateService) {
    this.messageElement = this.renderer.createElement("span");
    this.renderer.setStyle(this.messageElement, "margin-left", "2px");
    this.renderer.setStyle(this.messageElement, "color", "red");
    this.renderer.setStyle(this.messageElement, "font-size", "11px");

    this.messageElement.innerText = "";
    const parent = this.el.nativeElement.parentNode;

    if (parent) {
      this.renderer.appendChild(parent, this.messageElement);
    }
  }

  @HostListener("input", ["$event"]) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const maxLength = this.appLimitCharacterInput;

    if (input.value.length > maxLength) {
      input.value = input.value.slice(0, maxLength);
      event.preventDefault();
    }

    if (input.value.length >= maxLength) {
      this.messageElement.innerText = this.translate.instant("limit_character");
    } else {
      this.messageElement.innerText = "";
    }
  }

  @HostListener("blur") onBlur() {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input.value.length >= this.appLimitCharacterInput) {
      this.messageElement.innerText = this.translate.instant("limit_character");
    } else {
      this.messageElement.innerText = "";
    }
  }
}
