import { Contract } from "../models/contract";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "angular-custom-modal";
import { ActivatedRoute, Router } from "@angular/router";
import { ContractService } from "../services/contract.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { PrintPreviewService } from "src/app/shared/services/print-preview.service";

@Component({
  selector: "app-contract",
  templateUrl: "./contract.component.html",
})
export class ContractComponent implements OnInit {
  isShowingSidebar = false;
  @Input() contract!: Contract;
  @Input() editUrlNavigation!: string;
  public entityname = "contract";
  public id!: number;
  isLoading = false;
  @ViewChild("modal") modal!: ModalComponent;
  today: string = new Date().toLocaleDateString("sr-RS");


  constructor(public route: ActivatedRoute, public contractService: ContractService, public router: Router, public translate: TranslateService,public printPreviewService: PrintPreviewService) {
  }
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.id = Number(params.get("id"));
    });
  }
  editContract() {
    this.router.navigate([this.editUrlNavigation]);
  }

  sendContract() {
    this.modal.open();
  }

  printContract() {
    this.isLoading = true;
    const subscription = this.contractService.generateContractHtml(this.contract.id).subscribe({
      next: (htmlContent) => {
        this.isLoading = false;
        this.printPreviewService.printFunctionality(htmlContent);
        subscription.unsubscribe();
      },
      complete: () => {
        subscription.unsubscribe();
        this.isLoading = false;
      },
    });
  }

  finishSending() {
    this.modal.close();
  }
  getOrganizerInfo(): string {
    const organizer = this.contract?.organizer;
    const nameLabel = this.translate.instant('organizer.name'); 
    const phoneLabel = organizer.phone ? ` ${this.translate.instant('organizer.phone')}: ${organizer.phone},` : '';
    const licenceLabel = organizer.licence ? ` ${this.translate.instant('organizer.licence')}: ${organizer.licence}` : '';
    const organizerInfo = `${nameLabel} ${organizer.name}${phoneLabel}${licenceLabel}`;
    return organizerInfo;
  }
  getResponsiblePersonInfo(): string {
    const organizer = this.contract?.organizer;
    const responsiblePersonLabel = this.translate.instant('organizer.responsiblePerson'); 
    const emailLabel = this.translate.instant('organizer.email'); 
    const nameInfo = organizer.firstName && organizer.lastName ? `${responsiblePersonLabel} ${organizer.firstName} ${organizer.lastName}, ` : '';
    const emailInfo = organizer.email ? `${emailLabel} ${organizer.email}` : '';
    return `${nameInfo}${emailInfo}`;
  }
  getOfficeBranchInfo(): string {
    const officeBranch = this.contract?.officeBranch;
    const nameInfo = officeBranch.name ? `${this.translate.instant('contract.agent')}: ${officeBranch.name}` : '';
    const addressInfo = officeBranch.fullAddress ? `${this.translate.instant('contract.address')}: ${officeBranch.fullAddress} ${officeBranch.city}` : '';
    const licenceInfo = officeBranch.licence ? `${this.translate.instant('contract.license_number')}: ${officeBranch.licence}` : '';
    const infoArray = [nameInfo, addressInfo, licenceInfo].filter(info => info); 
    return infoArray.join(', '); 
  }
}
