import * as moment from "moment";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { SimpleTicket } from "../models/ticket";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { TicketsService } from "../services/tickets.service";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { dateFormatValidator, dateTimeFormatValidator } from "src/app/shared/validators/date.validator";

import { toggleAnimation } from "src/app/shared/animations";
@Component({
  selector: "app-ticket-create",
  templateUrl: "./ticket.create.component.html",
  animations: [toggleAnimation],
})
export class TicketCreateComponent implements OnInit, OnDestroy {
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;

  public id: number | undefined;
  public form!: FormGroup;
  public isSubmitForm = false;
  private oneWaySubscription: Subscription | undefined;
  private routeSubscriber!: Subscription;

  @Input() defaultTicket: SimpleTicket = {} as SimpleTicket;
  @Output() submitTicket: EventEmitter<SimpleTicket> = new EventEmitter<SimpleTicket>();
  ngOnInit(): void {
    this.initFormIntern({} as SimpleTicket);
    const today = moment().format("DD.MM.YYYY");
    this.form.patchValue({
      reservationDate: today,
    });
    this.routeSubscriber = this.route.params.subscribe((params) => {
      this.form.get("type")?.setValue(params["type"]);
    });
  }

  ngOnDestroy() {
    this.oneWaySubscription?.unsubscribe();
    this.routeSubscriber.unsubscribe();
  }

  constructor(public fb: FormBuilder, public route: ActivatedRoute, public store: Store<AppState>, public translate: TranslateService, private ticketService: TicketsService, public utilService: UtilService) {}

  setTicket(ticket: SimpleTicket) {
    this.initFormIntern(ticket);
  }

  initFormIntern(ticket: SimpleTicket | undefined = undefined) {
    const { id = this.id, oneWay = true, destination, departure, departureDate, arrivalDate, company, reservationDate = moment().format("DD.MM.YYYY"), type } = ticket || {};
    this.id = id;
    const formattedDepartureDate = departureDate ? moment(departureDate).format("DD.MM.YYYY HH:mm") : null;
    const formattedArrivalDate = arrivalDate ? moment(arrivalDate).format("DD.MM.YYYY HH:mm") : null;
    const formattedReservationDate = reservationDate ? moment(reservationDate).format("DD.MM.YYYY") : null;
    const country = departure?.country || destination?.country || null;
    this.form = this.fb.group({
      oneWay: [oneWay],
      destination: [destination, Validators.required],
      departure: [departure],
      departureDate: [formattedDepartureDate, { validators: [Validators.required, dateTimeFormatValidator()], updateOn: "blur" }],
      arrivalDate: [formattedArrivalDate, { validators: [dateFormatValidator()], updateOn: "blur" }],
      company: [company, Validators.required],
      reservationDate: [formattedReservationDate, { validators: [dateFormatValidator()], updateOn: "blur" }],
      type: [type],
      country: [country],
    });

    this.oneWaySubscription = this.form.get("oneWay")?.valueChanges.subscribe((value) => {
      const arrivalDateControl = this.form.get("arrivalDate");
      if (arrivalDateControl) {
        if (value) {
          arrivalDateControl.clearValidators();
        } else {
          arrivalDateControl.setValidators([Validators.required, dateFormatValidator()]);
        }
        arrivalDateControl.updateValueAndValidity();
      }
    });
  }

  dateTimeMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/];
  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  submitForm() {
    this.isSubmitForm = true;
    this.submitTicket.emit(this.form.getRawValue());
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  getTicket(): SimpleTicket {
    return { ...this.form.getRawValue() } as SimpleTicket;
  }

  initForm(ticket: SimpleTicket) {
    this.setTicket(ticket);
  }
}
