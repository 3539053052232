import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { StatisticOrganizer } from "src/app/domain/statisticsmoney/models/statisticorganizer";

@Component({
  selector: "app-organizer-statistics",
  templateUrl: "./organizer-statistics.component.html",
})
export class OrganizerStatisticsComponent implements OnChanges {
  @Input() statisticsOrganizer: StatisticOrganizer[] = [];
  simpleColumnStackedOrganizer: any;

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["statisticsOrganizer"] && this.statisticsOrganizer.length > 0) {
      this.updateChartOrganizer();
    }
  }

  updateChartOrganizer() {
    const organizerNames = this.statisticsOrganizer.map((item) => item.organizerName);
    const travelCounts = this.statisticsOrganizer.map((item) => item.travelCount);

    this.translate.get("organizer_statistics.numberOfTravels").subscribe((translatedLabel) => {
      this.simpleColumnStackedOrganizer = {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 1,
        },
        colors: ["#46b196", "#af4133", "#d4483b", "#fbcb3e"],
        xaxis: {
          categories: organizerNames,
          axisBorder: {
            color: "#191e3a",
          },
        },
        yaxis: {
          opposite: false,
          reversed: false,
        },
        grid: {
          borderColor: "#ADD8E6",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        fill: {
          opacity: 0.8,
        },
        series: [
          {
            name: translatedLabel,
            data: travelCounts,
          },
        ],
      };
    });
  }
}
