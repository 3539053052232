import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import { FormGroup } from "@angular/forms";
import { Organizer } from "../models/organizers";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { ModalService } from "src/app/shared/services/modal.service";
import { OrganizersService } from "../services/organizers.service";
import { TableService } from "src/app/shared/services/table.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { OrganizerFormComponent } from "src/app/domain/organizers/organizer-form/organizer-form.component";

@Component({
  templateUrl: "./organizers.table.component.html",
  animations: [toggleAnimation],
})
export class OrganizersTableComponent extends BaseTableComponent<Organizer> implements OnInit {
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild(OrganizerFormComponent) formComponent!: OrganizerFormComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  public organizers: Organizer[] = [];
  public editMode = false;
  organizerId = 0;
  form!: FormGroup;
  mergeMode = false;
  foundOrganizer: Organizer[] = [];
  name = "";
  term!:string;
  filteredOrganizers: Organizer[] = [];
  selectedOrganizerIds: number[] = [];
  isLoading = false;
  organizerData!: Organizer;
  organizer!: Observable<Organizer[]>;
  selectedOrganizer: Organizer[] = [];

  constructor(
    private organizersService: OrganizersService,
    public translate: TranslateService,
    public override tableService: TableService,
    public override store: Store<AppState>,
    private positioningService: PositioningService,
    private modalService: ModalService
  ) {
    super(store, tableService, `organizers/paginated`);
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.loadOrganizers();
  }

  loadOrganizers() {
    this.filteredOrganizers = [];
    this.foundOrganizer = [];
    this.selectedOrganizerIds = [];
    this.organizer = this.organizersService.getOrganizernew().pipe(map((organizers: Organizer[]) => organizers.filter((organizer) => organizer.id !== this.organizerId)));
  }

  addOrganizer() {
    this.modalService.openOrganizerAddModal().then((result) => {
      if (result) {
        this.updatePaginationProps.next(this.getPaginationParams());
        this.loadOrganizers();
        showMessage(PopupType.Success, this.translate.instant("organizer_data.added_organizer_successfully"));
      }
    })
  }

  updateOrganizer(organizer: Organizer) {
    this.modalService.openOrganizerEditModal(organizer).then((result) => {
      if (result) {
        this.updatePaginationProps.next(this.getPaginationParams());
        this.loadOrganizers();
        showMessage(PopupType.Success, this.translate.instant("organizer_data.updated_organizer_successfully"));
      }
    })
  }
  override getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("organizator_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("organizator_info.name"), hide: false } as DatatableColumn,
      { field: "fullName", title: this.translate.instant("organizator_info.agency_representative"), hide: false, sort: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("organizator_info.created_at"), hide: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("organizator_info.actions"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  public exportCols = [
    { field: "customerNameAndSurname", title: this.translate.instant("organizers-export.customerNameAndSurname"), isUnique: true, hide: true } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("organizers-export.customerPhoneNumber"), hide: false, sort: false } as DatatableColumn,
    { field: "numberOfPassangers", title: this.translate.instant("organizers-export.numberOfPassangers"), hide: false } as DatatableColumn,
    { field: "checkInDate", title: this.translate.instant("organizers-export.checkInDate"), hide: false } as DatatableColumn,
    { field: "caheckOutDate", title: this.translate.instant("organizers-export.caheckOutDate"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("organizers-export.bruto"), hide: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("organizers-export.neto"), hide: false } as DatatableColumn,
    { field: "profit", title: this.translate.instant("organizers-export.profit"), hide: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("organizers-export.status"), hide: false } as DatatableColumn,
    { field: "purchaseDate", title: this.translate.instant("organizers-export.purchaseDate"), hide: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("organizers-export.user"), hide: false } as DatatableColumn,
  ];
  addMerge(id: number, name: string) {
    this.organizerId = id;
    this.name = name;
    this.mergeMode = true;
    this.editMode = false;
    this.modal.open(); 
    this.foundOrganizer = [];
    this.filteredOrganizers = [];
    this.term = "";
  }
  mergeSelectedOrganizer(selectedOrganizerIds: number[]) {
    if (selectedOrganizerIds.length > 0 && this.organizerId) {
      this.isLoading = true;
      const mergeSubscription =  this.organizersService.mergeOrganizers(this.organizerId, selectedOrganizerIds).subscribe({
        next: () => {
          mergeSubscription.unsubscribe();
          this.loadOrganizers();
          showMessage(PopupType.Success, this.translate.instant("organizer_data.organizers_merged_successfully"));
          this.isLoading = false;
          this.modal.close();
          this.updatePaginationProps.next(this.getPaginationParams());
        },
        error: () => {
          showMessage(PopupType.Warning, this.translate.instant("organizer_data.error_merging_organizers"));
          this.isLoading = false;
        },
      });
    } else {
      showMessage(PopupType.Warning, this.translate.instant("organizer_data.select_organizers_to_merge"));
    }
  }
}
