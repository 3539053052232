import { Store } from "@ngrx/store";
import { User } from "./model/user";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { Component, OnDestroy } from "@angular/core";
import { AuthService } from "./service/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "src/app/service/app.service";
import { resetUser, setUser } from "../store/auth/auth.actions";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { toggleAnimation } from "../shared/animations";

@Component({
  moduleId: module.id,
  templateUrl: "./login.html",
  animations: [toggleAnimation],
  providers: [AuthService],
})
export class LoginComponent implements OnDestroy {
  store: any;
  loading = false;
  protected loginForm: FormGroup;
  passwordVisible = false;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(public router: Router, private fb: FormBuilder, public storeData: Store<any>, private appSetting: AppService, private authService: AuthService, public translate: TranslateService) {
    this.initStore();
    this.loginForm = fb.group({
      email: new FormControl(""),
      password: new FormControl(""),
    });
  }

  async initStore() {
    this.storeData.dispatch(resetUser());
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  login() {
    if (this.loginForm.valid) {
      const trimmedEmail = this.loginForm.get("email")?.value.trim();
      this.loginForm.get("email")?.setValue(trimmedEmail);

      this.loading = true;
      this.authService
        .authenticate(this.loginForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (user: User) => {
            this.storeData.dispatch(setUser({ user }));
            this.router.navigate(["/dashboard/sales"]);
          },
          error: () => (this.loading = false),
        });
    }
  }

  changeLanguage(item: any) {
    this.translate.use(item.code);
    this.appSetting.toggleLanguage(item);
    window.location.reload();
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
}
