import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { GroupTravelDestination } from "../../group-travels/models/group-travel";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-group-destinations-form",
  templateUrl: "./group-destinations.form.component.html",
  animations: [toggleAnimation],
})
export class GroupDestinationsFormComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    start: [null, Validators.required],
    end: [null, Validators.required],
    days: [null, Validators.required],
    nights: [null, Validators.required],
    notes: [null, Validators.required],
    service: [null, Validators.required],
    destination: [null, Validators.required],
    hotel: [null],
  });

  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;
  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  @Input() defaultDestination: GroupTravelDestination = {} as GroupTravelDestination;
  @Input() id: number | null = null;
  @Output() submitDestination: EventEmitter<GroupTravelDestination> = new EventEmitter<GroupTravelDestination>();

  constructor(public fb: FormBuilder, public utilService: UtilService, private translate: TranslateService) {}

  initForm(initDestination: GroupTravelDestination) {
    const {
      start = this.form ? this.form.controls["start"].value : "",
      end = this.form ? this.form.controls["end"].value : "",
      notes = this.form ? this.form.controls["notes"].value : "",
      days = this.form ? this.form.controls["days"].value : "",
      nights = this.form ? this.form.controls["nights"].value : "",
      service = this.form ? this.form.controls["service"].value : null,
      destination = this.form ? this.form.controls["destination"].value : null,
      hotel = this.form ? this.form.controls["hotel"].value : "",
    } = initDestination || {};
    const country = destination?.country || null;
    this.form = this.fb.group({
      start: [start, Validators.required],
      end: [end, Validators.required],
      days: [days, Validators.required],
      nights: [nights, Validators.required],
      notes: [notes],
      service: [service, Validators.required],
      destination: [destination, { validators: [Validators.required], updateOn: "blur" }],
      hotel: [hotel, { validators: [Validators.required], updateOn: "blur" }],
      country: [country],
    });
  }

  setDaysNights() {
    if (!this.form.controls["start"].errors && !this.form.controls["end"].errors && !!this.form.controls["start"].value && !!this.form.controls["end"].value) {
      const start = moment(this.form.controls["start"].value, "DD.MM.YYYY", true);
      const end = moment(this.form.controls["end"].value, "DD.MM.YYYY", true);
      const difference = Math.abs(start.diff(end, "days"));

      this.form.controls["days"].setValue(difference);
      this.form.controls["nights"].setValue(difference - 1);
    }
  }

  checkIfValid(): boolean {
    return this.form.valid;
  }

  ngOnInit(): void {
    this.initForm(this.defaultDestination);
  }

  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      return;
    }
    const { start, end, days, nights, notes, service, destination, hotel } = this.form.getRawValue();
    const editedDestination = {
      ...this.defaultDestination,
      start,
      end,
      days,
      nights,
      notes,
      service,
      destination,
      hotel,
    };
    this.submitDestination.emit(editedDestination);
  }
}
