<div class="fixed inset-0 z-50 flex items-center justify-center">
  <div class="w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
    <div class="relative w-full max-w-md rounded-lg bg-white">
      <!-- Close Button positioned at the top right -->
      <button (click)="closeModal()" class="absolute right-2 top-2 text-gray-500 hover:text-gray-700">
        <icon-x class="h-5 w-5"></icon-x>
        <!-- Use your icon component or an SVG for the close icon -->
      </button>
      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <div [ngClass]="form.controls['description'].touched ? (form.controls['description'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
          <label for="description">{{ "destinations_data.name_destination" | translate }}</label>
          <input id="description" type="text" placeholder="{{ 'hotel_info.name' | translate }}" class="form-input" formControlName="description"  [appLimitCharacterInput]="60"/>
          <small class="text-danger" *ngIf="form.controls['description'].touched && form.controls['description'].errors"></small>
        </div>
        <div class="mb-4" [ngClass]="form.controls['country'].touched ? (form.controls['country'].errors ? 'has-error' : 'has-success') : ''">
          <label for="customCountry">{{ "country_create.country" | translate }}</label>
          <app-autocomplete-remote
            [type]="EntityPicklistType.Countries"
            labelName="name"
            placeholder="{{ 'country_create.enter_country' | translate }}"
            [selectControl]="form"
            labelForId="customCountry"
            formControlName="country"
            [addCallback]="utilService.countriesAdd('country', form).bind(utilService)"
          ></app-autocomplete-remote>
          <small class="text-danger" *ngIf="form.controls['country'].touched && form.controls['country'].errors"></small>
        </div>
        <button type="submit" class="btn btn-primary w-full" [disabled]="isLoading || form.invalid">
          <ng-container *ngIf="!isLoading; else loadingTemplate">{{ editMode ? "Update" : "Save" }}</ng-container>
          <ng-template #loadingTemplate>
            <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
          </ng-template>
        </button>
        
      </form>
    </div>
  </div>
</div>
