<div class="relative space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <div class="relative">
        <input [(ngModel)]="search" (keydown.enter)="onSearchEnter()" type="text" class="form-input pr-10" placeholder="{{ 'search_placeholder' | translate }}" [appLimitCharacterInput]="60"/>
        <span class="absolute inset-y-0 right-0 flex items-center pr-3">
          <icon-search class="text-gray-500"></icon-search>
        </span>
      </div>

      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <app-export-table
          *ngIf="!isDraftShown"
          [exportApi]="'api/statistics/home/export'"
          [filterType]="filterType"
          [sortColumn]="sortColumn"
          [sortDirection]="sortDirection"
          [search]="search"
          [columns]="exportCols"
          [filename]="'Group Travel Table Export'"
        ></app-export-table>
      </div>
    </div>
    <app-global-table-filters
      [filterType]="filterType"
      [total]="totalRecords"
      [withDept]="withDeptPlans"
      [withoutDept]="withoutDeptPlans"
      (selectFilter)="onSelectionChange($event)"
      [draftFilterVisible]="true"
    ></app-global-table-filters>

    <div class="datatable" *ngIf="!isDraftShown; else draftTable">
      <ng-datatable
        [rows]="items"
        [stickyHeader]="true"
        [height]="'670px'"
        [columns]="cols"
        [sortable]="true"
        [isServerMode]="true"
        [page]="pageNumber"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [loading]="loading"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
        [showNumbersCount]="3"
      >
        <ng-template slot="createdAt" let-value="data"> {{ value.createdAt | date : "dd.MM.yyyy" }} </ng-template>
        <ng-template slot="entityId" let-value="data">
          <div class="dropdown inline-flex">
            <button
              *ngIf="getIconAndTooltip(value.entity_name)"
              type="button"
              [ngxTippy]="tooltipTemplate"
              [tippyName]="value.id"
              [tippyContext]="{ data: value }"
              [tippyProps]="{ placement: 'right', interactive: true, maxWidth: 400, theme: 'light' }"
              class="btn btn-outline-secondary rounded-circle"
              [routerLink]="getRouterLink(value)"
            >
              <div class="flex h-6 w-6 items-center gap-2">
                <ng-container *ngIf="getIconAndTooltip(value.entity_name) as icon">
                  <ng-container *ngComponentOutlet="icon"></ng-container>
                </ng-container>
              </div>
            </button>
          </div>
        </ng-template>
        <ng-template slot="customerId" let-value="data"> {{ value.customerFullName }} </ng-template>
        <ng-template slot="number_of_passangers" let-value="data"> {{ value.number_of_passangers }} </ng-template>
        <ng-template slot="destinations" let-value="data">
          <span>
            <ng-container *ngIf="value.departure !== null; else hasOnlyDestination">
              {{ value.departure?.description }} <span *ngIf="value.destination">- {{ value.destination.description }}</span>
            </ng-container>
            <ng-template #hasOnlyDestination>
              {{ value.destination ? value.destination.description : "-" }}
            </ng-template>
          </span>
        </ng-template>
        <ng-template slot="getStatus" let-value="data">
          <span *ngIf="value.status; else elseBlock" class="badge bg-success">{{ "paid" | translate }}</span>
          <ng-template #elseBlock>
            <span class="badge bg-danger">{{ "not-paid" | translate }}</span>
          </ng-template>
        </ng-template>
        <ng-template slot="bruto" let-value="data">{{ value.bruto | currency : "EUR" }}</ng-template>
        <ng-template slot="createdAt" let-value="data">{{ value.createdAt | date : "dd.MM.yyyy" }}</ng-template>
        <ng-template slot="depth" let-value="data"
          ><span class="text-danger">{{ value.depth | currency : "EUR" }}</span>
        </ng-template>
        <ng-template slot="actions" let-value="data">
          <div class="flex w-max items-center justify-between">
            <div hlMenu class="dropdown">
              <a hlMenuButton href="javascript:;">
                <icon-horizontal-dots class="rotate-90 opacity-70" />
              </a>
              <div #dropdownPlaceholder *hlMenuItems>
                <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="navigateToDetails(value)">
                      <icon-info-circle class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "tickets_info.details" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="navigateToEdit(value)">
                      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "tickets_info.edit" | translate }}
                    </a>
                  </li>
									<li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="deletePlan(value.id)">
                      <icon-trash-lines class="shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "delete_quick_label" | translate }}
                    </a>
                  </li>
                </app-message-portal>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-datatable>
    </div>
    <ng-template #draftTable><app-draft-table></app-draft-table></ng-template>
  </div>

  <ng-template #tooltipTemplate let-name let-data="data" class="bg-white">
    <container-element [ngSwitch]="data.entity_name">
      <app-transfer-plan-quickview [id]="data.entityId" *ngSwitchCase="'Transfer'"> </app-transfer-plan-quickview>
      <app-group-travel-plan-quickview [id]="data.entityId" *ngSwitchCase="'GroupTravel'"></app-group-travel-plan-quickview>
      <app-travel-quick-view [id]="data.entityId" *ngSwitchCase="'Travel'"></app-travel-quick-view>
      <app-other-services-quick-view [id]="data.entityId" *ngSwitchCase="'PlanService'"></app-other-services-quick-view>
      <app-ticket-quick-view [id]="data.entityId" *ngSwitchCase="'Ticket'"></app-ticket-quick-view>
    </container-element>
  </ng-template>
</div>
