<div>
  <div class="mb-6 flex flex-wrap items-center justify-center gap-4 p-5 lg:justify-end print:hidden">
    <ng-content></ng-content>
    <button type="button" class="btn btn-info gap-2" (click)="sendVoucher()">
      <icon-send />
      {{ "vaucher.edit_message_and_send" | translate }}
    </button>

    <button type="button" class="btn btn-primary gap-2" (click)="printVoucher()" [disabled]="isLoading">
      <icon-printer />
      {{ "vaucher.print" | translate }}
    </button>

    <button type="button" class="btn btn-warning gap-2" (click)="navigateToEditVoucher()">
      <icon-edit />
      {{ "vaucher.edit" | translate }}
    </button>
  </div>
  <app-voucher-template [voucher]="announcement" [label]="'travel_announcement'"></app-voucher-template>

  <modal #modal class="modal-top custom-modal">
    <ng-template #modalBody>
      <app-file-send
        [title]="'send_announcement_dialog_title' | translate"
        fileType="announcement"
        [id]="announcement.id"
        [email]="announcement.email"
        [passangers]="announcement.passengers"
        [sendDocumentFn]="announcementService.sendAnnouncement(travelId)"
        messageRs="Postovani, Najava je u dodatku emaila. Molim Vas za potrvdu. Hvala na poverenju!"
        messageEn="Greetings, Please confirm. Payment is in the email attachment. Thank you for your trust in us"
        (notifyWhenSend)="finishSending()"
      >
      <h2 class="mb-2">{{ "agent_form.data" | translate }}:</h2>
      <div class="flex">
        <table class="w-full border border-gray-400 table-auto">
          <tbody>
            <tr class="border border-gray-300 bg-gray-50">
              <td class="p-2 border-b border-gray-300">{{ "agent_form.name" | translate }}: {{ organizer.name }}</td>
              <td class="p-2 border-b border-gray-300">{{ "agent_form.fullname" | translate }}: {{  organizer.firstName  }} {{ organizer.lastName }}</td>
            </tr>
            <tr class="border border-gray-300 bg-gray-50">
              <td class="p-2 border-b border-gray-300">{{ "agent_form.phone" | translate }}: {{ organizer.phone}}</td>
              <td class="p-2 border-b border-gray-300">{{ "agent_form.licence" | translate }}: {{ organizer.licence }}</td>
            </tr>
            <tr class="border border-gray-300 bg-gray-50">
              <td class="p-2 border-b border-gray-300">{{ "agent_form.email" | translate }}: {{ announcement.email}}</td>
              <td class="p-2 border-b border-gray-300">{{ "agent_form.address" | translate }}: {{ organizer.address }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </app-file-send>
    </ng-template>
  </modal>
</div>
