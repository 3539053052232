import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BaseHttpService } from "src/app/service/base.http.service";
import { Ticket } from "src/app/domain/tickets/models/ticket";
import { Observable } from "rxjs";
import { Travel, TravelDraft } from "src/app/domain/travels/models/travel";

@Injectable({
  providedIn: "root",
})
export class CheckoutService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  createTicket(body: Ticket): Observable<Ticket> {
    const apiUrl = `${this.apiUrl}/api/tickets/plan`;
    return this.http.post<Ticket>(apiUrl, body);
  }

  createTicketDraft(body: Ticket): Observable<Ticket> {
    return this.http.post<Ticket>(`${this.apiUrl}/api/draft/ticket`, body);
  }

  updateTicketDraft(body: Ticket): Observable<Ticket> {
    return this.http.put<Ticket>(`${this.apiUrl}/api/draft/ticket`, body);
  }

  updateTicket(id: number, body: Ticket): Observable<Ticket> {
    const apiUrl = `${this.apiUrl}/api/tickets/plan/${id}`;
    return this.http.put<Ticket>(apiUrl, body);
  }

  fetchTicket(id: number): Observable<Ticket> {
    return this.http.get<Ticket>(`${this.apiUrl}/api/tickets/${id}`);
  }

  createTravelPlan(body: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/api/travels/plan`;
    return this.http.post<any>(apiUrl, body);
  }

  createPlan(body: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/api/plans`;
    return this.http.post<any>(apiUrl, body);
  }

  createTravel(body: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/api/travels`;
    return this.http.post<any>(apiUrl, body);
  }

  createTravelDraft(body: Travel): Observable<Travel> {
    return this.http.post<Travel>(`${this.apiUrl}/api/travels/draft`, body);
  }

  updateTravelDraft(id: number, body: Travel): Observable<Travel> {
    return this.http.put<Travel>(`${this.apiUrl}/api/travels/draft/${id}`, body);
  }

  finishTravelDraft(id: number, body: Travel): Observable<Travel> {
    return this.http.put<Travel>(`${this.apiUrl}/api/travels/plan/draft/${id}`, body);
  }

  fetchDraftTravel(id: number): Observable<TravelDraft> {
    return this.http.get<TravelDraft>(`${this.apiUrl}/api/draft/travel/${id}`);
  }

  updateTravel(id: number, body: Travel): Observable<Travel> {
    const apiUrl = `${this.apiUrl}/api/travels/plan/${id}`;
    return this.http.put<Travel>(apiUrl, body);
  }

  fetchTravel(id: number): Observable<Travel> {
    return this.http.get<Travel>(`${this.apiUrl}/api/travels/${id}`);
  }

 
}
