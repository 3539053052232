import { GroupTravelPassanger } from "../../group-travels/models/group-travel";

export enum PassangerTypes {
  infants = "infants",
  adults = "adults",
  children = "children",
}

export interface Customer {
  id: number;
  guid: string;
  lifeStage: PassangerTypes;
  fullName: string;
  name: string;
  surname: string;
  address: string;
  dateOfBirth: string | undefined;
  profession: string;
  email: string;
  phoneNumber: string;
  imageUrl: string;
  imbg: string;
  notPaidPlansCount: number;
  numberOfArrangements: number;
  numberOfTickets: number;
  passport: string;
  passportExpirationDate: string | undefined;
  passportIssuingDate: string | undefined;
  reservationNumber: string | undefined;
  createdAt: string;
  updatedAt: string;
  groupTravelPassangers: GroupTravelPassanger[];
  passangerState: string;
  totalPrice: string;
  groupNumber: number;
  mainCustomer: boolean;
  isMainCustomer: boolean;
}
