import { Router } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { SaleBase } from "src/app/domain/sales/models/sale";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { PaymentService } from "./../../../domain/sales/services/payment.service";
import { PrintPreviewService } from "../../services/print-preview.service";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent {
  @Input() title = "Confirmation";
  @Input() message = "Are you sure you want to proceed?";
  @Input() confirmButtonText = "Confirm";
  @Input() cancelButtonText = "Cancel";
  @Input() plan!: SaleBase;
  @Input() isOnlyAvans = false;
  @Input() navigateToRoute!: string;
  isLoading = false;
  @ViewChild("modal") modal!: ModalComponent;
  @Output() confirmed = new EventEmitter<boolean>();
  @Output() notifyWhenEditAndSend: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public paymentService: PaymentService, private router: Router, public printPreviewService: PrintPreviewService) {}
  printInvoice() {
    this.isLoading = true;
    const subscription = this.paymentService.generateInvoiceHtml(this.plan.id).subscribe({
      next: (htmlContent) => {
        this.isLoading = false;
        this.printPreviewService.printFunctionality(htmlContent);
        subscription.unsubscribe();
      },
      complete: () => {
        subscription.unsubscribe();
        this.isLoading = false;
      },
    });
  }
  getCurrentDate(): Date {
    return new Date();
  }

  dismiss() {
    this.confirmed.emit(false);
    this.router.navigate([this.navigateToRoute]);
  }
  finishSending() {
    this.confirmed.emit(false);
    this.notifyWhenEditAndSend.emit();
    if (this.navigateToRoute) {
      this.router.navigate([this.navigateToRoute]);
    } else {
      this.router.navigate(["dashboard/sales"]);
    }
  }
}
