import { TranslateService } from "@ngx-translate/core";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TransfersService } from "../services/transfers.service.service";
import { Customer } from "../../customers/models/customer";
import { Transfer, TransferPassanger, TransferPlanAccumulator } from "src/app/domain/transfers/models/transfer";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { MessagePortalComponent } from "src/app/portal/portal";

@Component({
  selector: "app-passengers-table",
  templateUrl: "./passengers-table.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class PassengersTableComponent implements OnInit {
  @Input() transferId: number | undefined;
  public exportApiUrl!: string;
  transferData!: TransferPassanger[];
  currentPage = 1;
  pageSize = 2;
  paginatedData: any[] = [];
  rows: Customer[] = [];
  jsonData = this.rows;
  totalRows = 0;
  treeview1: any = [];
  transfer!: Transfer;
  groupTravelPassangersPlan: any;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  ngOnInit(): void {
    this.fetchTransfers();
  }

  constructor(private transferService: TransfersService, private translate: TranslateService, private positioningService: PositioningService) {}
  fetchTransfers() {
    if (this.transferId !== undefined) {
      this.transferService.getTransferData(this.transferId).subscribe((response) => {
        this.transfer = response;
        this.groupTravelPassangersPlan = this.transformTransferData(this.transfer);
        this.exportApiUrl = `api/Transfers/transfer/${this.transferId}`;
      });
    }
  }
  transformTransferData(transfer: Transfer) {
    const groupedTransferData = transfer.transferPlans?.reduce((acc: TransferPlanAccumulator, transferPlan) => {
      const { id, transferId, plan, customerId } = transferPlan;
      const passengers: TransferPassanger[] = transferPlan.transferPassangers || [];
      const mainPassenger = passengers.find((passenger) => passenger.customer.id === customerId);
      const otherPassengers = passengers.filter((passenger) => passenger.customer.id !== customerId);

      if (!acc[id]) {
        acc[id] = {
          transferId: transferId,
          transferPlanId: id,
          mainCustomer: mainPassenger ? mainPassenger.customer : null,
          passengers: otherPassengers,
          paymentType: plan.paymentType?.title || null,
          totalPrice: plan.bruto - plan.neto,
          planStatus: plan.status,
        };
      }

      return acc;
    }, {} as TransferPlanAccumulator);

    return Object.values(groupedTransferData);
  }

  cols = [
    { field: "id", title: this.translate.instant("transfer_table.id"), isUnique: true, hide: true } as DatatableColumn,
    { field: "numberPassangers", title: this.translate.instant("group_travel_table.numberPassangers"), hide: false, sort: false } as DatatableColumn,
    { field: "name", title: this.translate.instant("transfer_table.firstname"), hide: false } as DatatableColumn,
    { field: "surname", title: this.translate.instant("transfer_table.lastname") } as DatatableColumn,
    { field: "dateOfBirth", title: this.translate.instant("transfer_table.date_of_birth"), hide: true } as DatatableColumn,
    { field: "address", title: this.translate.instant("transfer_table.address"), hide: true } as DatatableColumn,
    { field: "phoneNumber", title: this.translate.instant("transfer_table.phone_number") } as DatatableColumn,
    //jel treba ovo ?{ field: "lifeStage", title: this.translate.instant("transfer_table.ilife_stage") } as DatatableColumn,
    { field: "passangerState", title: this.translate.instant("transfer_table.passanger_state") } as DatatableColumn,
    { field: "totalPrice", title: this.translate.instant("transfer_table.total_price") } as DatatableColumn,
    { field: "actions", title: this.translate.instant("group_travel_table.actions"), hide: false, sort: false } as DatatableColumn,
  ];
  public exportCols = [
    { field: "id", title: this.translate.instant("passanger_export.id") } as DatatableColumn,
    { field: "customerNameAndSurname", title: this.translate.instant("passanger_export.customerNameAndSurname") } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("passanger_export.customerPhoneNumber") } as DatatableColumn,
    { field: "departure", title: this.translate.instant("passanger_export.departure") } as DatatableColumn,
    { field: "destination", title: this.translate.instant("passanger_export.destination") } as DatatableColumn,
    { field: "status", title: this.translate.instant("passanger_export.status") } as DatatableColumn,
    { field: "saleDate", title: this.translate.instant("passanger_export.saleDate") } as DatatableColumn,
    { field: "user", title: this.translate.instant("passanger_export.user") } as DatatableColumn,
  ];

  openVoucher() {
    return Math.ceil(this.transferData.length / this.pageSize);
  }
  updateColumn(col: DatatableColumn) {
    col.hide = !col.hide;
    this.cols = [...this.cols];
  }
  toggleTreeview(id: string) {
    const index = this.treeview1.indexOf(id);
    if (index > -1) {
      this.treeview1.splice(index, 1);
    } else {
      this.treeview1.push(id);
    }
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
}
