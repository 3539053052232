import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintPreviewService {

  printFunctionality(htmlContent: string) {
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    document.body.appendChild(iframe);

    const doc = iframe.contentWindow?.document;
    if (doc) {
      doc.open();
      doc.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              @page {
                size: A4; 
                margin: 0;
              }
              body {
                margin: 20mm; 
                font-family: Arial, sans-serif; 
              }
              h1, h2, h3 {
                page-break-after: avoid; 
              }
            </style>
          </head>
          <body onload="window.print(); window.close();">
            ${htmlContent}
          </body>
        </html>
      `);
      doc.close();
    } else {
      console.error('Failed to access iframe contentWindow.');
    }
  }
}
