import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { Destination, DestinationStatistics } from "../models/destination";

@Injectable({
  providedIn: "root",
})
export class DestinationsService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getDestinationsStatistics(id: number, from: string, to: string) {
    return this.http.get<DestinationStatistics>(`${this.apiUrl}/api/Statistics/destination/${id}?from=${from}&to=${to}`);
  }
  mergeDestination(rootId: number, listOfIds: number[]): Observable<any> {
    const url = `${this.apiUrl}/api/destinations/merge`;
    const body = { rootId, listOfIds };
    return this.http.post(url, body, { responseType: "text" });
  }
  getDestinations() {
    return this.http.get<Destination[]>(`${this.apiUrl}/api/destinations`);
  }
  updateDestination(id:number, data:any) {
    return this.http.put<Destination>(`${this.apiUrl}/api/destinations/${id}`, data);
  } 
  addDestination(data: any) {
    return this.http.post<Destination>(`${this.apiUrl}/api/destinations`, data);
  }
}
