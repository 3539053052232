import { BehaviorSubject, map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EtouristCheckResponse } from "../models/generics";
import { BaseHttpService } from "src/app/service/base.http.service";
import { ETouristCity, ETouristMunicipality, ETouristStreet } from "../models/etourist";

@Injectable({
  providedIn: "root",
})
export class ETouristService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  private availabilitySubject = new BehaviorSubject<boolean>(false);

  // Observable to access the shared state
  getAvailability(): Observable<boolean> {
    return this.availabilitySubject.asObservable();
  }

  // Optionally expose a way to get the latest value synchronously
  isAvailable(): boolean {
    return this.availabilitySubject.getValue();
  }

  checkAvailability(): Observable<boolean> {
    return this.http.get<EtouristCheckResponse>("/api/ETourist/credentials/check").pipe(
      map((response) => {
        this.availabilitySubject.next(response.check); // Update shared state
        return response.check;
      })
    );
  }

  getMunicipality(): Observable<ETouristMunicipality[]> {
    return this.http.get<ETouristMunicipality[]>(`${this.apiUrl}/api/ETourist/municipalities`);
  }

  getCities(municipalitiesId: number): Observable<ETouristCity[]> {
    return this.http.get<ETouristCity[]>(`${this.apiUrl}/api/ETourist/localities/${municipalitiesId}`);
  }

  getStreets(cityId: number): Observable<ETouristStreet[]> {
    return this.http.get<ETouristStreet[]>(`${this.apiUrl}/api/ETourist/streets/${cityId}`);
  }
}
