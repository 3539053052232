import { createReducer, on } from "@ngrx/store";
import { setFromTo, reset } from "./datepicker.actions";

export interface DateTimeState {
  from: Date;
  to: Date | undefined;
}

export const initialState: DateTimeState = { from: new Date(new Date().getFullYear() - 1, 11, 1), to: undefined };

export const dateTimeReducer = createReducer(
  initialState,
  on(setFromTo, (state: DateTimeState, { from, to }: { from: Date; to: Date | undefined }) => ({
    ...state,
    from,
    to,
  })),
  on(reset, () => initialState)
);
