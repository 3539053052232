import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { BaseHttpService } from "src/app/service/base.http.service";
import { Company, CompanyStatistics } from "../models/company";
import { GenericResponse } from "src/app/shared/models/generics";
import { Sale } from "../../sales/models/sale";

@Injectable({
  providedIn: "root",
})
export class CompaniesService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  getCompanyStatistics(id: number) {
    return this.http.get<CompanyStatistics>(`${this.apiUrl}/api/companies/statistics/${id}`);
  }

  addCompany(data: any) {
    return this.http.post<Company>(`${this.apiUrl}/api/companies`, data);
  }

  updateCompany(id: number, data: any) {
    return this.http.put<Company>(`${this.apiUrl}/api/companies/${id}`, data);
  }

  deleteCompany(id: number) {
    return this.http.delete(`${this.apiUrl}/api/companies/${id}`, { responseType: "text" });
  }

  mergeCompany(rootId: number, listOfIds: number[]): Observable<any> {
    const url = `${this.apiUrl}/api/Companies/merge`;
    const body = { rootId, listOfIds };
    return this.http.post(url, body, { responseType: "text" });
  }
  getCompany() {
    return this.http.get<Company[]>(`${this.apiUrl}/api/companies`);
  }
}
