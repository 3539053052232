import * as moment from "moment";
import { ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GroupTravelsService } from "../services/group-travels.service";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { GroupTravel, GroupTravelDestination } from "../models/group-travel";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import { brutoLessThanNetoValidator } from "src/app/shared/validators/sale.validator";
import { TravelDestinationCreateComponent } from "../../travels/travel-destination.create/travel-destination.create.component";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-group-travel",
  templateUrl: "./group-travel.component.html",
  animations: [toggleAnimation],
})
export class GroupTravelComponent implements OnInit {
  @ViewChild("destinationCreateComp") destinationCreateComp!: TravelDestinationCreateComponent;
  EntityPicklistType = EntityPicklistType;
  PicklistType = PicklistType;
  id!: number;

  public form!: FormGroup;
  public isShowedForm = false;
  selectedIndex: number | null = null;
  groupTravelDestinations: GroupTravelDestination[] = [];
  public editDestination!: GroupTravelDestination | undefined;
  mainDestination: GroupTravelDestination | null = null;
  public showDestinationForm = false;
  isLoading = false;
  constructor(
    public fb: FormBuilder,
    public groupTravelService: GroupTravelsService,
    private router: Router,
    private route: ActivatedRoute,
    public utilService: UtilService,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {}

  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  ngOnInit(): void {
    this.initForm({} as GroupTravel);
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      if (this.router.url.includes("/edit")) {
        this.fetchEntities();
      }
    });
  }
  submitForm() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, "Please fill all required fields");
      this.form.markAllAsTouched();
      return;
    }
    const groupTravelObject = this.createGroupTravelObject();
    if (groupTravelObject.groupTravelDestinations.length === 0) {
      showMessage(PopupType.Warning, "Please add at least one destination");
      return;
    }

    if (this.id) {
      this.isLoading = true;
      const updateSub = this.groupTravelService.updateGroupTravel(this.id, groupTravelObject).subscribe({
        next: () => {
          this.isLoading = false;
          updateSub.unsubscribe();
          this.router.navigate(["dashboard/group-travels"]);
          showMessage(PopupType.Success, this.translate.instant("popup.updated_successfully"));
        },
        error: () => {
          this.isLoading = false;
          updateSub.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("popup.error_updated_successfully"));
        },
      });
    } else {
      this.isLoading = true;
      const createSub = this.groupTravelService.createGroupTravel(groupTravelObject).subscribe({
        next: () => {
          this.isLoading = false;
          createSub.unsubscribe();
          this.router.navigate(["dashboard/group-travels"]);
          showMessage(PopupType.Success, this.translate.instant("popup.created_successfully"));
        },
        error: () => {
          this.isLoading = false;
          createSub.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("popup.error_updated_successfully")); 
        },
      });
    }
  }

  fetchEntities() {
    this.groupTravelService.getGroupTravelData(this.id).subscribe((response) => {
      const { groupTravelDestinations } = response;
      this.initForm(DateTimeFormatter.formatGroupTravelDates(response));

      for (const travelDestination of groupTravelDestinations) {
        if (travelDestination.isMainDestination) {
          this.mainDestination = travelDestination;
        } else {
          this.groupTravelDestinations.push(
            DateTimeFormatter.formatGroupTravelDestinationDates({
              ...travelDestination,
            } as GroupTravelDestination)
          );
        }
      }
    });
  }
  toggleDestinationForm(): void {
    this.showDestinationForm = !this.showDestinationForm;
  }

  initForm(groupTravel: GroupTravel) {
    const { name, departure, start, end, bruto, neto, organizer ,type} = groupTravel || ({} as GroupTravel);

    const country = departure?.country || null;
    this.form = this.fb.group(
      {
        name: [name, Validators.required],
        departure: [departure, Validators.required],
        start: [start, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        end: [end, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        bruto: [bruto, Validators.required],
        neto: [neto, Validators.required],
        organizer: [organizer, Validators.required],
        country: [country],
        type: [type, { validators: [Validators.required], updateOn: "blur" }],
      },
      { validators: brutoLessThanNetoValidator }
    );
  }
  toEditDestination(destination: GroupTravelDestination) {
    this.editDestination = undefined;
    setTimeout(() => {
      this.editDestination = destination;
    });
  }

  removeDestination(id: number) {
    this.editDestination = undefined;
    this.groupTravelService.deleteGroupTravelDestination(id).subscribe(() => {
      this.groupTravelDestinations = this.groupTravelDestinations.filter((e) => e.id !== id);
    });
    showMessage(PopupType.Success, this.translate.instant("group_travels_info.destination_removed_successfully"));
  }

  handleDestination(destination: any) {
    const id = destination.id;
    if (id) {
      const index = this.groupTravelDestinations.findIndex((e) => e.id === id);
      if (index !== -1) {
        this.groupTravelDestinations[index] = { ...this.groupTravelDestinations[index], ...destination };
        showMessage(PopupType.Success, this.translate.instant("popup.destination_updated_successfully"));
      } else {
        this.groupTravelDestinations.push(destination);
        showMessage(PopupType.Success, this.translate.instant("popup.destination_saved_successfully"));
      }
    } else {
      this.groupTravelDestinations.push(destination);
      showMessage(PopupType.Success, this.translate.instant("popup.destination_saved_successfully"));
    }
    this.showDestinationForm = false;
    this.editDestination = undefined;
  }

  createGroupTravelObject(): any {
    this.mainDestination = this.destinationCreateComp.getTravel();
    const { id, name, departure, start, end, bruto, neto, organizer,type} = this.form.getRawValue();
    const groupDestinations = this.groupTravelDestinations.map((e) => {
      return {
        id: e.id,
        start: moment(e.start, "DD.MM.YYYY").toISOString(),
        end: moment(e.end, "DD.MM.YYYY").toISOString(),
        days: e.days,
        nights: e.nights,
        notes: e.notes,
        serviceId: e.service?.id,
        destinationId: e.destination?.id,
        hotelId: e.hotel?.id,
        isMainDestination: e.isMainDestination,
        roomDescription: e.roomDescription,
      };
    });
    if (this.mainDestination) {
      groupDestinations.push({
        id: this.mainDestination.id,
        start: moment(this.mainDestination.start, "DD.MM.YYYY").toISOString(),
        end: moment(this.mainDestination.end, "DD.MM.YYYY").toISOString(),
        days: this.mainDestination.days,
        nights: this.mainDestination.nights,
        notes: this.mainDestination.notes,
        serviceId: this.mainDestination.service?.id,
        destinationId: this.mainDestination.destination?.id,
        hotelId: this.mainDestination.hotel?.id,
        isMainDestination: this.mainDestination.isMainDestination,
        roomDescription: this.mainDestination.roomDescription,
      });
    }
    return {
      id,
      name,
      bruto,
      neto,
      start: moment(start, "DD.MM.YYYY").toISOString(),
      end: moment(end, "DD.MM.YYYY").toISOString(),
      departureId: departure?.id,
      groupTravelDestinations: groupDestinations,
      organizerId: organizer?.id,
      typeId: type.id,
    };
  }

  hasNonMainDestinations(): boolean {
    return this.groupTravelDestinations.some((destination) => !destination.isMainDestination);
  }
}
