// src/app/domain/quick-views/group-travel-plan-quickview/group-travel-plan-quickview.component.ts
import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GroupTravelsService } from "../../group-travels/services/group-travels.service";
import { GroupTravelPlan, GroupTravelDestination, GroupTravel } from "../../group-travels/models/group-travel";
import { Customer } from "../../customers/models/customer";

@Component({
  selector: 'app-group-travel-quick-view',
  templateUrl: './group-travel-quick-view.component.html',
  styleUrls: ['./group-travel-quick-view.component.css']
})
export class GroupTravelQuickViewComponent implements OnInit {
  @Input() data!: GroupTravel;
  @Input() id!: number;



  constructor(public translate: TranslateService, private groupTravelService: GroupTravelsService) { }
  getReservationNumber(pass: Customer[]) {
    if (pass) {
      const arr = pass.map((passenger) => passenger.reservationNumber);
      return arr.join(", ");
    }
    return "";
  }

  numberOfPassangers() {
    if (this.data && this.data.groupTravelPassangers) {
      return this.data.groupTravelPassangers.length;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.id) {
      const getSubscription = this.groupTravelService.getGroupTravelData(this.id).subscribe((travelPlan) => {
        this.data = travelPlan;
        console.log(travelPlan, "quickview");
        getSubscription.unsubscribe();
      });
    }
  }
}