import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { Transfer } from "../models/transfer";
import { ActivatedRoute } from "@angular/router";
import { Ticket } from "../../tickets/models/ticket";
import { Travel } from "../../travels/models/travel";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { Customer } from "../../customers/models/customer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TravelsService } from "../../travels/services/travels.service";
import { TicketsService } from "../../tickets/services/tickets.service";
import { TransfersService } from "../services/transfers.service.service";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-transfer-passangers-table",
  templateUrl: "./transfer-passanger-table.component.html",
})
export class TransferPassangersTableComponent implements OnInit, OnDestroy {
  private id!: number;
  PicklistType = PicklistType;
  protected ticket!: Ticket;
  protected travel!: Travel;
  protected passangers: Customer[] = [];
  EntityPicklistType = EntityPicklistType;
  public isSubmitForm = false;
  protected transferId!: number | undefined;
  protected ticketId!: number | undefined;
  protected travelId!: number | undefined;
  type!: string;
  public transfer!: Transfer;

  @Input() defaultTicket: Ticket = {} as Ticket;
  @Input() submitTicket: EventEmitter<Ticket> = new EventEmitter<Ticket>();
  @ViewChild("sale") sale: any;
  private routeSubscriber!: Subscription;

  ngOnInit(): void {
    this.routeSubscriber = this.route.paramMap.subscribe((params) => {
      const transferId = params.get("transferId");
      this.transferId = transferId ? Number(transferId) : undefined;
      this.type = params.get("type")?.toString() || "";
      const id = params.get("id");
      if (this.type === "ticket") this.ticketId = id ? parseInt(id) : undefined;
      else this.travelId = id ? parseInt(id) : undefined;
      this.fetchTransfer();
      this.fetchTicketData();
      this.fetchPassangers();
      this.fetchTransfer();
    });
  }

  ngOnDestroy() {
    this.routeSubscriber.unsubscribe();
  }

  constructor(
    private ticketService: TicketsService,
    public store: Store<AppState>,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private travelService: TravelsService,
    private transferService: TransfersService
  ) {}

  submitForm() {
    this.isSubmitForm = true;
    this.addTransferForTicket();
  }

  fetchTicketData() {
    if (this.type === "ticket") {
      const getSub = this.ticketService.getTicketData(this.ticketId).subscribe((response) => {
        getSub.unsubscribe();
        this.ticket = response;
      });
    } else {
      const getSub = this.travelService.getTravelData(this.travelId).subscribe((response) => {
        getSub.unsubscribe();
        this.travel = response;
      });
    }
  }

  fetchPassangers() {
    if (this.type === "ticket") {
      const getSub = this.ticketService.getPassangersData(this.ticketId).subscribe((response) => {
        getSub.unsubscribe();
        this.passangers = response;
      });
    } else {
      const getSub = this.travelService.getPassangersData(this.travelId).subscribe((response) => {
        getSub.unsubscribe();
        this.passangers = response;
      });
    }
  }

  addPassangerToTransfer() {
    const sale = this.sale.getSale();
    sale.dueDate = moment(sale.dueDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss");
    const date = {
      entityId: this.type === "ticket" ? this.ticketId : this.travelId,
      plan: sale,
      transferId: this.transferId,
      passangerIds: this.passangers.map((passanger) => passanger.id),
    };
    return date;
  }

  addTransferForTicket() {
    const data = this.addPassangerToTransfer();
    if (this.type === "ticket") this.addTransferToTicket(data, "ticket");
    else this.addTransferToTicket(data, "travel");
  }

  addPassangersToTicket() {
    this.addTransferForTicket();
  }

  fetchTransfer() {
    if (this.transferId !== undefined) {
      const getTransferSub = this.transferService.getTransferById(this.transferId).subscribe((response) => {
        getTransferSub.unsubscribe();
        this.transfer = response;
      });
    }
  }

  addDirection(id: number) {
    if (id === 1) this.transferId = this.transfer.returnTransfer?.id;
    else this.transferId = this.transfer.id;
  }

  addTransferToTicket(data: any, type: string) {
    if (this.transferId !== undefined) {
      const transferToTiketSub = this.transferService.addTransferToTicket(this.transferId, data, type).subscribe({
        next: () => {
          transferToTiketSub.unsubscribe();
          showMessage(PopupType.Success, this.translate.instant("transfer.added_passangers_successfully"));
        },
        error: () => {
          transferToTiketSub.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("transfer.error_while_adding_passangers"));
        },
      });
    }
  }
}
