import * as moment from "moment";
import { Router } from "@angular/router";
import { Component, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Sale } from "src/app/domain/sales/models/sale";
import { StyleUtil } from "src/app/shared/utils/style.util";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { CheckoutService } from "../../services/checkout.service";
import { Customer } from "src/app/domain/customers/models/customer";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { Travel, TravelDraft } from "src/app/domain/travels/models/travel";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";
import { AnnouncementService } from "src/app/domain/travels/services/announcement.service";
import { TravelCreateComponent } from "src/app/domain/travels/travel.create/travel.create.component";
import { PassangersListComponent } from "src/app/domain/passangers/passangers.list/passangers.list.component";
import { DestinationsListComponent } from "src/app/domain/destinations/destinations.list/destinations.list.component";
import { TravelDestinationCreateComponent } from "src/app/domain/travels/travel-destination.create/travel-destination.create.component";
import { ValidationService } from "src/app/shared/services/validation.service";

@Component({
  selector: "app-travel-checkout-create",
  templateUrl: "./travel-checkout.create.component.html",
})
export class TravelCheckoutCreateComponent {
  @ViewChild("mainDestination") mainDestination!: TravelDestinationCreateComponent;
  @ViewChild("destinations") destinations!: DestinationsListComponent;
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("travelBase") travelComponent!: TravelCreateComponent;
  public activeTab = 1;
  public id!: number;
  public sale!: Sale;
  public travel!: TravelDraft;
  public customer!: Customer;
  public showTravelForm = false;
  public showMainDestination = true;
  public isLoading = false;
  note!: string;
  public tabSizeHandler = StyleUtil.getTabWidth;

  constructor(private router: Router, public checkoutService: CheckoutService, public translate: TranslateService, private announcementService: AnnouncementService, public validationService: ValidationService) { }

  proceedToNextStage(nextStep: number) {
    if (nextStep === 2) {
      this.customer = this.passangerList.getPassangers().find((e) => e.mainCustomer) as Customer;
      if (!this.validationService.validatePassanger(this.customer, this.passangerList.checkValidity())) {
        return;
      }
      this.showTravelForm = true;
    }
    if (this.activeTab === 1) {
      const mainCustomer = this.validationService.validateMainCustomer(this.passangerList.getPassangers());
      if (!mainCustomer) return;
      this.customer = mainCustomer;
    }

    if (this.activeTab === 2 && nextStep !== 1) {
      if (!this.travelComponent.checkIfValid()) {
        showMessage(PopupType.Danger, this.translate.instant("forms_invalid_ticket_sale_checkout"));
        return;
      }
    }

    this.activeTab = nextStep;
  }
  createDraftTravel() {
    if (!this.travelComponent.checkIfValid()) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.travel_hasnt_been_defined_fully"));
      return;
    }
    if (!this.mainDestination.checkIfValid()) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.main_destination_missing_info"));
      return;
    }
    if (!this.passangerList.checkValidity()) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.passanger_validation_failed"));
      return;
    }

    this.travel = this.travelComponent.getTravel() as TravelDraft;

    const travelDraft = this.createReservationObject();
    this.isLoading = true;
    const createSubscription = this.checkoutService.createTravelDraft({ ...travelDraft, ...travelDraft.plan }).subscribe({
      next: (r: Travel) => {
        this.isLoading = false;
        this.id = r.id;
        showMessage(PopupType.Success, this.translate.instant("ticket_edited_successfully"));
        createSubscription.unsubscribe();
        this.router.navigate([`dashboard/travels/draft/edit/${this.id}`], {
          queryParams: {
            activeTab: 3,
          },
        });
      },
      error: (error) => {
        this.isLoading = false;
        showMessage(PopupType.Danger, error);
        createSubscription.unsubscribe();
      },
    });
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  createReservationObject(): any {
    const { start, end, type, days, nights, organizer, departure, avans } = this.travel;

    const { id } = this.customer;

    // Extracting necessary IDs
    const organizerId: number = organizer?.id;
    const departureId: number | undefined = departure ? departure?.id : undefined;

    let passangers = this.passangerList.getPassangers().map((e) => {
      return { customerId: e.id, lifeStage: e.lifeStage, isMainCustomer: e.isMainCustomer };
    });

    const otherDest = this.destinations.getDestinations() || [];

    const mainCustomer = this.passangerList.getPassangers().find((e) => e.mainCustomer);
    if (mainCustomer?.mainCustomer == false) passangers = passangers.filter((e) => e.customerId !== mainCustomer?.id);

    const destinations = [...otherDest, { ...this.mainDestination.getTravel(), isMainDestination: true } as TravelDestination];

    const newReservation = {
      start: moment(start, "DD.MM.YYYY").toISOString(),
      end: moment(end, "DD.MM.YYYY").toISOString(),
      days,
      nights,
      typeId: type?.id,
      customerId: id,
      numberOfPassangers: passangers.length,
      passangers,
      departureId,
      organizerId,
      plan: {
        avans: avans,
        dueDate: moment().endOf("year"),
        bruto: 1,
        neto: 0,
        customerId: id,
        notes: this.note,
      },
      travelDestinations: destinations.map((p) => {
        const { travelId, destination, hotel, start: travelStart, end: travelEnd, service, days, nights, roomDescription, isMainDestination } = p;
        return {
          start: moment(travelStart, "DD.MM.YYYY").toISOString(),
          end: moment(travelEnd, "DD.MM.YYYY").toISOString(),
          days,
          nights,
          roomDescription,
          serviceId: service?.id,
          destinationId: destination?.id,
          hotelId: hotel?.id,
          travelId: travelId,
          isMainDestination,
        };

      }),
    };

    return newReservation;
  }

  returnBack() {
    this.activeTab = this.activeTab > 0 ? --this.activeTab : 1;
  }
  onNotify(data: any) {
    this.mainDestination.setStartAndEnd(data);
  }
}
