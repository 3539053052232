import * as moment from "moment";
import { Sale } from "src/app/domain/sales/models/sale";
import { Payment } from "src/app/domain/sales/models/payment";
import { Ticket } from "src/app/domain/tickets/models/ticket";
import { Travel } from "src/app/domain/travels/models/travel";
import { Customer } from "src/app/domain/customers/models/customer";
import { CashRegister } from "src/app/domain/sales/models/cash-register";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";
import { GroupTravel, GroupTravelDestination } from "src/app/domain/group-travels/models/group-travel";

export class DateTimeFormatter {
  static formatDateToString(date: Date | null): string {
    if (!date) return "";
    // Get year, month, and day from the date object
    const year = date.getFullYear();
    // Add 1 to month because getMonth() returns zero-based month (0 for January, 1 for February, etc.)
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    // Concatenate year, month, and day with '-' as separator
    return `${year}-${month}-${day}`;
  }

  static formatDateToLocalString(date: Date | null | undefined): string {
    if (!date) return "";
    // Get year, month, and day from the date object
    const year = date.getFullYear();
    // Add 1 to month because getMonth() returns zero-based month (0 for January, 1 for February, etc.)
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    // Concatenate year, month, and day with '-' as separator
    return `${day}.${month}.${year}`;
  }

  static formatDate(date: any) {
    if (date) {
      const dt = new Date(date);
      const month = dt.getMonth() + 1 < 10 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1;
      const day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
      return day + "/" + month + "/" + dt.getFullYear();
    }
    return "";
  }

  static formatCustomerDates(customer: Customer): Customer {
    return {
      ...customer,
      dateOfBirth: customer.dateOfBirth ? moment(customer.dateOfBirth).format("DD.MM.YYYY") : undefined,
      passportExpirationDate: customer.passportExpirationDate ? moment(customer.passportExpirationDate).format("DD.MM.YYYY HH:mm") : undefined,
      passportIssuingDate: customer.passportIssuingDate ? moment(customer.passportIssuingDate).format("DD.MM.YYYY HH:mm") : undefined,
    };
  }

  static formatTicketDates(ticket: Ticket): Ticket {
    return {
      ...ticket,
      arrivalDate: ticket.arrivalDate ? moment(ticket.arrivalDate).format("DD.MM.YYYY") : undefined,
      createdAt: moment(ticket.createdAt).format("DD.MM.YYYY HH:mm"),
      departureDate: moment(ticket.departureDate).format("DD.MM.YYYY HH:mm"),
      reservationDate: moment(ticket.reservationDate).format("DD.MM.YYYY"),
      updatedAt: moment(ticket.updatedAt).format("DD.MM.YYYY HH:mm"),
    };
  }

  static formatPlanDates(plan: Sale): Sale {
    return {
      ...plan,
      dueDate: plan.dueDate ? moment(plan.dueDate).format("DD.MM.YYYY") : undefined,
      createdAt: moment(plan.createdAt).format("DD.MM.YYYY HH:mm"),
      reservationDate: moment(plan.reservationDate).format("DD.MM.YYYY HH:mm"),
      updatedAt: moment(plan.updatedAt).format("DD.MM.YYYY HH:mm"),
    };
  }

  static formatCashRegisterDates(cashRegister: CashRegister): CashRegister {
    return {
      ...cashRegister,
      createdAt: moment(cashRegister.createdAt).format("DD.MM.YYYY"),
      processedAt: moment(cashRegister.processedAt).format("DD.MM.YYYY"),
      processedFrom: moment(cashRegister.processedFrom).format("DD.MM.YYYY"),
    };
  }

  static formatTravelDates(travel: Travel): Travel {
    return {
      ...travel,
      start: moment(travel.start).format("DD.MM.YYYY"),
      end: moment(travel.end).format("DD.MM.YYYY"),
      createdAt: moment(travel.createdAt).format("DD.MM.YYYY HH:mm"),
      updatedAt: moment(travel.updatedAt).format("DD.MM.YYYY HH:mm"),
    };
  }

  static formatTravelDestinationDates(travelDestination: TravelDestination): TravelDestination {
    return {
      ...travelDestination,
      guid: crypto.randomUUID(),
      start: moment(travelDestination.start).format("DD.MM.YYYY"),
      end: moment(travelDestination.end).format("DD.MM.YYYY"),
      createdAt: moment(travelDestination.createdAt).format("DD.MM.YYYY HH:mm"),
      updatedAt: moment(travelDestination.updatedAt).format("DD.MM.YYYY HH:mm"),
    };
  }

  static formatGroupTravelDestinationDates(groupTravelDestination: GroupTravelDestination): GroupTravelDestination {
    return {
      ...groupTravelDestination,
      guid: crypto.randomUUID(),
      start: moment(groupTravelDestination.start).format("DD.MM.YYYY"),
      end: moment(groupTravelDestination.end).format("DD.MM.YYYY"),
    };
  }

  static formatGroupTravelDates(groupTravel: GroupTravel): GroupTravel {
    return {
      ...groupTravel,
      start: moment(groupTravel.start).format("DD.MM.YYYY"),
      end: moment(groupTravel.end).format("DD.MM.YYYY"),
    };
  }

  static formatPaymentDates(payment: Payment): Payment {
    return {
      ...payment,
      paymentDate: moment(payment.paymentDate).format("DD.MM.YYYY"),
    };
  }
}
