import { Store } from "@ngrx/store";
import { User } from "../model/user";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { AuthService } from "../service/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "src/app/service/app.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { resetUser, setUser } from "src/app/store/auth/auth.actions";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  moduleId: module.id,
  templateUrl: "./lockscreen.html",
  animations: [toggleAnimation],
})
export class LockscreenComponent implements OnInit, OnDestroy {
  store: any;
  userData: any;
  loading = false;
  protected loginForm: FormGroup;
  passwordVisible = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(public router: Router, private fb: FormBuilder, public storeData: Store<any>, private appSetting: AppService, private authService: AuthService, public translate: TranslateService) {
    this.loginForm = fb.group({
      email: new FormControl(""),
      password: new FormControl(""),
    });

    // Load minimal user data from sessionStorage on initialization
    this.userData = JSON.parse(sessionStorage.getItem("minimalUserData") || "{}");
  }

  ngOnInit() {
    this.initStore();
  }

  async initStore() {
    this.storeData
      .select((d) => d.auth)
      .pipe(takeUntil(this.destroy$))
      .subscribe((auth) => {
        if (auth && auth.user) {
          const currentUserData = JSON.parse(sessionStorage.getItem("minimalUserData") || "{}");
          if (currentUserData.email !== auth.user.email) {
            this.userData = { name: auth.user.name, surname: auth.user.surname, email: auth.user.email };
            sessionStorage.setItem(
              "minimalUserData",
              JSON.stringify({
                name: auth.user.name,
                surname: auth.user.surname,
                email: auth.user.email,
              })
            );
          }
        }
      });

    this.storeData.dispatch(resetUser());

    this.storeData
      .select((d) => d.index)
      .pipe(takeUntil(this.destroy$))
      .subscribe((d) => {
        this.store = d;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  login() {
    this.loginForm.patchValue({ email: this.userData.email });

    if (this.loginForm.valid) {
      this.loading = true;
      this.authService
        .authenticate(this.loginForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (user: User) => {
            this.storeData.dispatch(setUser({ user }));
            this.router.navigate(["/dashboard/sales"]);
          },
          error: () => (this.loading = false),
        });
    }
  }

  changeLanguage(item: any) {
    this.translate.use(item.code);
    this.appSetting.toggleLanguage(item);
    if (this.store.locale?.toLowerCase() === "ae") {
      this.storeData.dispatch({ type: "toggleRTL", payload: "rtl" });
    } else {
      this.storeData.dispatch({ type: "toggleRTL", payload: "ltr" });
    }
    window.location.reload();
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
}
