<div class="panel mt-5 p-5">
  <form class="space-y-5 p-5" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
    <div class="flex space-x-4">
      <div>
        <label for="customOneWay" class="relative h-6 w-12">
          <input
            type="checkbox"
            formControlName="twoWay"
            class="custom_switch peer absolute z-10 h-full w-full cursor-pointer opacity-0"
            id="customOneWay"
            (change)="toggleTwoWay()"
            [checked]="twoWay === true"
          />
          <span
            for="customOneWay"
            class="outline_checkbox bg-icon block h-full rounded-full border-2 border-[#ebedf2] before:absolute before:bottom-1 before:left-1 before:h-4 before:w-4 before:rounded-full before:bg-[#ebedf2] before:bg-[url(/assets/images/close.svg)] before:bg-center before:bg-no-repeat before:transition-all before:duration-300 peer-checked:border-primary peer-checked:before:left-7 peer-checked:before:bg-primary peer-checked:before:bg-[url(/assets/images/checked.svg)] dark:border-white-dark dark:before:bg-white-dark"
          ></span>
        </label>
      </div>
      <div class="align-middle">
        <ng-container *ngIf="!twoWay; else returnTicketText">
          {{ "transfer.one_way_transport" | translate }}
        </ng-container>
        <ng-template #returnTicketText>
          {{ "transfer.two_way_transport" | translate }}
        </ng-template>
      </div>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-2">
      <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''">
        <label for="name">{{ "transfer.carrier_name" | translate }}</label>
        <input id="name" class="form-input" formControlName="name" />
        <ng-container *ngIf="form.controls['name'].touched && !form.controls['name'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "transfer.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['name'].touched && form.controls['name'].errors">
          <p class="mt-1 text-danger">{{ "transfer.please_provide_name" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
      <div [ngClass]="form.controls['departureDate'].touched ? (form.controls['departureDate'].errors ? 'has-error' : 'has-success') : ''">
        <label for="departureDate">{{ "transfer.departure_date" | translate }}</label>
        <input id="departureDate" type="text" class="form-input" formControlName="departureDate" placeholder="____.__.__ __:__" [textMask]="{ mask: dateTimeMask }" />
        <ng-container *ngIf="form.controls['departureDate'].touched && !form.controls['departureDateTime'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "transfer.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['departureDate'].touched && form.controls['departureDate'].errors">
          <p class="mt-1 text-danger">{{ "transfer.please_provide_departure_date" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['arrivalDate'].touched ? (form.controls['arrivalDate'].errors ? 'has-error' : 'has-success') : ''">
        <label for="arrivalDate">{{ "transfer.arrival_date" | translate }}</label>
        <input id="arrivalDate" type="text" class="form-input" formControlName="arrivalDate" placeholder="____.__.__ __:__" [textMask]="{ mask: dateTimeMask }" />
        <ng-container *ngIf="form.controls['arrivalDate'].touched && !form.controls['arrivalDate'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "transfer.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['arrivalDate'].touched && form.controls['arrivalDate'].errors">
          <p class="mt-1 text-danger">{{ "transfer.please_provide_arrival_date" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
        <app-destination-autocomplete [form]="form" formControlName="departure"></app-destination-autocomplete>
        <app-destination-autocomplete [form]="form" formControlName="destination"></app-destination-autocomplete>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
      <div [ngClass]="form.controls['vehicle'].touched ? (form.controls['vehicle'].errors ? 'has-error' : 'has-success') : ''">
        <label for="vehicle">{{ "transfer.vehicle" | translate }}</label>
        <app-select-remote id="customType" [type]="PicklistType.Vehicle" [selectControl]="form" labelForId="vehicle" formControlName="vehicle"></app-select-remote>
        <ng-container *ngIf="form.controls['vehicle'].touched && !form.controls['vehicle'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "transfer.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['vehicle'].touched && form.controls['vehicle'].errors">
          <p class="mt-1 text-danger">{{ "transfer.please_provide_vehicle" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['capacity'].touched ? (form.controls['capacity'].errors ? 'has-error' : 'has-success') : ''">
        <label for="capacity">{{ "transfer.vehicle_capacity" | translate }}</label>
        <input id="capacity" class="form-input" formControlName="capacity" />
        <ng-container *ngIf="form.controls['capacity'].touched && !form.controls['capacity'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "transfer.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['capacity'].touched && form.controls['capacity'].errors">
          <p class="mt-1 text-danger">{{ "transfer.please_provide_capacity" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-1">
      <button class="btn btn-primary" type="submit">{{ "transfer.update" | translate }}</button>
    </div>
  </form>
</div>
