<div class="panel">
  <div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
    <button type="submit" class="btn btn-success gap-2" (click)="save()">
      <icon-save />
      {{ "vaucher.save" | translate }}
    </button>
    <button class="btn btn-info cursor-pointer gap-2" (click)="cancelEdit()">
      <icon-save />
      {{ "vaucher.cancel" | translate }}
    </button>
  </div>
  <div class="grid text-center text-xl font-bold">
    <p> {{"vaucher.travel_announcement" | translate }}</p>
    <!-- //TODO:: translate  -->
    <p>br. {{ announcement.uniqueId }}</p>
  </div>
  <div class="col-span-2 flex flex-col sm:col-span-1 mt-4">
    <div class="-dark text-white"></div>
    <div>{{ "contract.passangers_information" | translate }}</div>
  </div>
  <div class="table-responsive mt-6">
    <table class="table-bordered mb-4 table">
      <thead class="bg-gray-100">
        <tr>
          <th rowspan="4">{{ "vaucher.guests" | translate }}</th>
          <th>{{ "vaucher.name_and_surname" | translate }}</th>
          <!-- <th>{{ "vaucher.date_of_birth" | translate }}</th> -->
          <th>{{ "vaucher.email" | translate }}</th>
          <th>{{ "vaucher.passport_number" | translate }}</th>
          <th>{{ "vaucher.phone_number" | translate }}</th>
        </tr>
      </thead>
      <tbody class="border-1">
        <ng-container *ngFor="let passanger of passangers; let i = index">
          <tr>
            <td>{{ i + 1 }}</td>
            <td><input class="form-input" [(ngModel)]="passanger.fullName" name="fullName{{ i }}" /></td>
            <!-- <td><input class="form-input" id="service" placeholder="__/__/____" [textMask]="{ mask: mask8 }" [(ngModel)]="passanger.dateOfBirth" name="dateOfBirth{{ i }}" /></td> -->
            <td><input class="form-input email" [(ngModel)]="passanger.email" name="email{{ i }}" /></td>
            <td><input class="form-input" id="service" [(ngModel)]="passanger.passport" name="passport{{ i }}" /></td>
            <td><input class="form-input" id="service" [(ngModel)]="passanger.phoneNumber" name="phoneNumber{{ i }}" /></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <form [formGroup]="form" (ngSubmit)="save()" class="z-0">
    <div class="col-span-2 flex flex-col sm:col-span-1">
        <div class="-dark text-white"></div>
        <div>{{ "contract.traveller_information" | translate }}</div>
    </div>
    <div class="mb-6 mt-2 p-4 border rounded-lg bg-gray-50">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
                <label for="agent" class="block text-sm font-medium text-gray-700">{{ "vaucher.organizer" | translate }}</label>
                <input id="agent" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="agent" />
                <ng-container *ngIf="form.controls['agent'].touched && form.controls['agent'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_agent" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="email" class="block text-sm font-medium text-gray-700">{{ "vaucher.email-organizer" | translate }}</label>
                <input id="email" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="email" />
                <ng-container *ngIf="form.controls['email'].touched && form.controls['email'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_email" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="accommodation" class="block text-sm font-medium text-gray-700">{{ "vaucher.accommodation" | translate }}</label>
                <input id="accommodation" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="accommodation" />
                <ng-container *ngIf="form.controls['accommodation'].touched && form.controls['accommodation'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_accommodation" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="hotel" class="block text-sm font-medium text-gray-700">{{ "vaucher.hotel" | translate }}</label>
                <input id="hotel" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="hotelLabel" />
                <ng-container *ngIf="form.controls['hotelLabel'].touched && form.controls['hotelLabel'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_hotel" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="transport" class="block text-sm font-medium text-gray-700">{{ "vaucher.transport" | translate }}</label>
                <input id="transport" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="transport" />
                <ng-container *ngIf="form.controls['transport'].touched && form.controls['transport'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_transport" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="service" class="block text-sm font-medium text-gray-700">{{ "vaucher.service" | translate }}</label>
                <input id="service" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="service" />
                <ng-container *ngIf="form.controls['service'].touched && form.controls['service'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_service" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="checkin" class="block text-sm font-medium text-gray-700">{{ "vaucher.check_in" | translate }}</label>
                <input id="checkin" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" [textMask]="{ mask: mask8 }" formControlName="checkin" />
                <ng-container *ngIf="form.controls['checkin'].touched && form.controls['checkin'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_check_in" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="checkout" class="block text-sm font-medium text-gray-700">{{ "vaucher.check_out" | translate }}</label>
                <input id="checkout" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" [textMask]="{ mask: mask8 }" formControlName="checkout" />
                <ng-container *ngIf="form.controls['checkout'].touched && form.controls['checkout'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_check_out" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="start" class="block text-sm font-medium text-gray-700">{{ "vaucher.start" | translate }}</label>
                <input id="start" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" [textMask]="{ mask: mask8 }" formControlName="start" />
                <ng-container *ngIf="form.controls['start'].touched && form.controls['start'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_start" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="end" class="block text-sm font-medium text-gray-700">{{ "vaucher.end" | translate }}</label>
                <input id="end" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" [textMask]="{ mask: mask8 }" formControlName="end" />
                <ng-container *ngIf="form.controls['end'].touched && form.controls['end'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_end" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="days" class="block text-sm font-medium text-gray-700">{{ "vaucher.days" | translate }}</label>
                <input id="days" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="days" />
                <ng-container *ngIf="form.controls['days'].touched && form.controls['days'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_days" | translate }}</small>
                </ng-container>
            </div>
            <div>
                <label for="nights" class="block text-sm font-medium text-gray-700">{{ "vaucher.nights" | translate }}</label>
                <input id="nights" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="nights" />
                <ng-container *ngIf="form.controls['nights'].touched && form.controls['nights'].errors">
                    <small class="mt-1 text-danger">{{ "vaucher.please_provide_nights" | translate }}</small>
                </ng-container>
            </div>
            <div>
              <label for="room" class="block text-sm font-medium text-gray-700">{{ "vaucher.rooms" | translate }}</label>
              <input id="room" class="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" type="text" formControlName="roomService" />
              <ng-container *ngIf="form.controls['roomService'].touched && form.controls['roomService'].errors">
                  <small class="mt-1 text-danger">{{ "vaucher.please_provide_rooms" | translate }}</small>
              </ng-container>
          </div>
        </div>
    </div>
</form>

</div>
