import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { GroupTravel } from "../models/group-travel";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { MessagePortalComponent } from "src/app/portal/portal";
import { ModalService } from "src/app/shared/services/modal.service";
import { TableService } from "src/app/shared/services/table.service";
import { GroupTravelsService } from "../services/group-travels.service";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  templateUrl: "./group-travels.table.component.html",
  animations: [toggleAnimation],
})
export class GroupTravelsTableComponent extends BaseTableComponent<GroupTravel> implements OnInit, OnDestroy {
  @ViewChild("cloneConfirmationModal")
  cloneConfirmationModal!: ModalComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  groupTravelToClone: any;
  private routeSubscriber!: Subscription;

  constructor(
    private modalService: ModalService,
    private groupTravelsService: GroupTravelsService,
    public translate: TranslateService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    private route: ActivatedRoute,
    private positioningService: PositioningService
  ) {
    super(store, tableService, `groupTravels/paginated`);
  }

  override ngOnInit(): void {
    this.routeSubscriber = this.route.params.subscribe((params) => {
      this.apiUrl = `grouptravels/paginated?type=${params["type"]}`;
      super.ngOnInit();
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.routeSubscriber.unsubscribe();
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  async createDuplicate(value: GroupTravel) {
    const title = this.translate.instant("clone.title");
    const message = this.translate.instant("clone.message");

    const confirmed = await this.modalService.confirm({
      title: title,
      message: message,
      confirmButtonText: this.translate.instant("common.confirm"),
      cancelButtonText: this.translate.instant("common.cancel"),
    });

    if (confirmed) {
      this.groupTravelToClone = value.id;
      this.cloneGroupTravel();
    }
  }

  cloneGroupTravel() {
    const cloneSubscription = this.groupTravelsService.createGroupTravelClone(this.groupTravelToClone).subscribe(() => {
      cloneSubscription.unsubscribe();
      this.cloneConfirmationModal.close();
      this.updatePaginationProps.next(this.getPaginationParams());
      showMessage(PopupType.Success, this.translate.instant("popup.object_cloned_successfully"));
    });
  }

  override getColumns(): void {
    this.cols = [
      { field: "actions", title: this.translate.instant("hotel_info.actions"), hide: true, sort: false } as DatatableColumn,
      { field: "id", title: this.translate.instant("group-travels_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("group-travels_info.name"), hide: false, sort: false } as DatatableColumn,
      { field: "bruto", title: this.translate.instant("group-travels_info.bruto"), hide: false } as DatatableColumn,
      { field: "neto", title: this.translate.instant("group-travels_info.neto"), hide: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("group-travels_info.created_at"), hide: false } as DatatableColumn,
      { field: "updatedAt", title: this.translate.instant("group-travels_info.updated_at"), hide: false } as DatatableColumn,
      { field: "details", title: this.translate.instant("group-travels_info.details"), hide: false } as DatatableColumn,
    ];
  }
  public exportCols = [
    { field: "id", title: this.translate.instant("group-travels-export.id"), isUnique: true, hide: true } as DatatableColumn,
    { field: "numberOfPassengers", title: this.translate.instant("group-travels-export.numberOfPassengers"), hide: false, sort: false } as DatatableColumn,
    { field: "departure", title: this.translate.instant("group-travels-export.departure"), hide: false } as DatatableColumn,
    { field: "destination", title: this.translate.instant("group-travels-export.destination"), hide: false } as DatatableColumn,
    { field: "startDate", title: this.translate.instant("group-travels-export.startDate"), hide: false } as DatatableColumn,
    { field: "returnDate", title: this.translate.instant("group-travels-export.returnDate"), hide: false } as DatatableColumn,
    { field: "serviceType", title: this.translate.instant("group-travels-export.serviceType"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("group-travels-export.bruto"), hide: true, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("group-travels-export.neto"), hide: true, sort: false } as DatatableColumn,
    { field: "eTuristaStatus", title: this.translate.instant("group-travels-export.eTuristaStatus"), hide: true, sort: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("group-travels-export.status"), hide: true, sort: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("group-travels-export.user"), hide: true, sort: false } as DatatableColumn,
  ];

  async deleteGroupTravel(id: number) {
    const title = this.translate.instant("delete.title");
    const message = this.translate.instant("delete.message");

    const confirmed = await this.modalService.confirm({
      title: title,
      message: message,
      confirmButtonText: this.translate.instant("common.confirm"),
      cancelButtonText: this.translate.instant("common.cancel"),
    });

    if (confirmed) {
      const deleteGroupTravelSubscription = this.groupTravelsService.deleteGroupTravel(id).subscribe({
        next: () => {
          deleteGroupTravelSubscription.unsubscribe();
          this.updatePaginationProps.next(this.getPaginationParams());
        },
        error: (error) => {
          deleteGroupTravelSubscription.unsubscribe();
          showMessage(PopupType.Danger, error.toString());
        },
      });
    }
  }
}
