import * as moment from "moment";
import { Sale } from "../../sales/models/sale";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Customer } from "../../customers/models/customer";
import { Transfer } from "../../transfers/models/transfer";
import { Component, OnInit, ViewChild } from "@angular/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { GroupTravelsService } from "../services/group-travels.service";
import { SaleCreateComponent } from "../../sales/sale.create/sale.create.component";
import { PassangersListComponent } from "../../passangers/passangers.list/passangers.list.component";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { ModalService } from "src/app/shared/services/modal.service";
import { ValidationService } from "src/app/shared/services/validation.service";

@Component({
  selector: "app-group-travel-plan",
  templateUrl: "./group-travel-plan.component.html",
})
export class GroupTravelPlanComponent implements OnInit {
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("sale") sales!: SaleCreateComponent;
  @ViewChild("modal") modal!: ModalComponent;

  activeTab = 1;
  customer!: Customer;
  passengers!: Customer[];
  sale!: Sale;
  groupTravelId!: number | undefined;
  transfer!: Transfer;
  isForwardDirection!: boolean;
  currentPlan: any;
  isLoading = false;

  constructor(public router: Router, private translate: TranslateService, private route: ActivatedRoute, private groupTravelService: GroupTravelsService,private modalService:ModalService,public validationService:ValidationService) {}
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      if (id !== null) {
        this.groupTravelId = parseInt(id);
      }
    });
  }
  proceedToNextStage(nextStep: number | undefined = undefined) {
    if (this.activeTab === 1) {
      const mainCustomer = this.validationService.validateMainCustomer(this.passangerList.getPassangers());
      if (!mainCustomer) return;
      this.customer = mainCustomer;
    }

    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  async submitGroupTravel() {
    try {
      if (this.sales.checkIfValid() && this.passangerList.checkValidity()) {
        this.passengers = this.passangerList.getPassangers();
        this.sale = this.sales.getSale();
        this.isLoading = true;
        const subscription = this.groupTravelService.addGroupTravelPassanger(this.fetchGroupTravelData()).subscribe({
          next: (response) => {
            this.isLoading = false;
            this.currentPlan = response.plan;
            this.sendInvoice();
            showMessage(PopupType.Success, this.translate.instant("group-travels_info.added_passangers_successfully"));
            subscription.unsubscribe();
          },
          error: () => {
            this.isLoading = false;
            showMessage(PopupType.Danger, this.translate.instant("group-travels_info.error_while_adding_passangers"));
            subscription.unsubscribe();
          },
        });
      }
    } catch (error) {
      showMessage(PopupType.Danger, this.translate.instant("group-travels_info.error_while_adding_passangers"));
      return;
    }
  }

  fetchGroupTravelData() {
    return {
      groupTravelId: this.groupTravelId,
      passangers: this.passengers.map((p: Customer) => {
        return { customerId: p.id, lifeStage: p.lifeStage, isMainCustomer: p.isMainCustomer };
      }),
      plan: { ...this.sale, dueDate: moment(this.sale.dueDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss"), customerId: this.customer.id },
      customerId: this.customer.id,
    };
  }
  async sendInvoice() {
    const confirm = await this.modalService.openModalForSendingInovice({
      title: this.translate.instant("send.avans"),
      message: this.translate.instant("confirm.send_avans"),
      confirmButtonText: this.translate.instant("yes"),
      cancelButtonText: this.translate.instant("no"),
      plan: this.currentPlan,
      navigateToRoute: `dashboard/sales`,
      isOnlyAvans: true,
    });
    if (confirm) {
      showMessage(PopupType.Success, this.translate.instant("popup.avans_sent_successfully"));
    }
  }
}
