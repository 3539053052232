import { Store } from "@ngrx/store";
import { Customer } from "../models/customer";
import { TranslateService } from "@ngx-translate/core";
import { CustomerPlan } from "../models/customer-plan";
import { AppState } from "src/app/store/index.selector";
import { CustomerStats } from "../models/customer-stat";
import { ActivatedRoute, Router } from "@angular/router";
import { MessagePortalComponent } from "src/app/portal/portal";
import { CustomersService } from "../services/customers.service";
import { TableService } from "src/app/shared/services/table.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-customer-stats",
  templateUrl: "./customer.stats.component.html",
  animations: [toggleAnimation],
})
export class CustomerStatsComponent extends BaseTableComponent<Customer> implements OnInit {
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  isShowingSidebar = false;
  protected id!: number;
  protected customer!: CustomerStats;
  fromDate!: string;
  toDate!: string;
  public entityname = "customers";
  public customerpaginated: CustomerPlan[] = [];
  public exportApiUrl!: string;
  public additionalInfo!: string;

  constructor(
    private customerStatsService: CustomersService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private positioningService: PositioningService,
    public override store: Store<AppState>,
    public override tableService: TableService
  ) {
    super(store, tableService, "");
  }

  override ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      if (this.id > 0) {
        this.fetchCustomerStats();
        this.apiUrl = `Statistics/customers/${this.id}/paginated`;
        this.exportApiUrl = `api/Customers/customer-plans/export/${this.id}`;
        super.ngOnInit();
      }
    });
  }

  fetchCustomerStats() {
    const customerStatsSubscription = this.customerStatsService.getCustomerStats(this.id).subscribe((data: CustomerStats) => {
      customerStatsSubscription.unsubscribe();
      this.customer = data;
      this.additionalInfo = [data.name, data.surname, data.email].filter((value) => value).join(" ");
      customerStatsSubscription.unsubscribe();
    });
  }

  override getColumns(): void {
    this.cols = [
      { field: "customerFullName", title: this.translate.instant("workerinfo.customerFullName"), hide: false } as DatatableColumn,
      { field: "company.name", title: this.translate.instant("workerinfo.company"), hide: false } as DatatableColumn,
      { field: "destination.description", title: this.translate.instant("workerinfo.destination"), hide: false } as DatatableColumn,
      { field: "departure.description", title: this.translate.instant("workerinfo.departure"), hide: false } as DatatableColumn,
      { field: "payed_in_payments", title: this.translate.instant("workerinfo.payed_in_payments"), hide: false } as DatatableColumn,
      { field: "entity_name", title: this.translate.instant("workerinfo.entity_name"), hide: false } as DatatableColumn,
      { field: "neto", title: this.translate.instant("workerinfo.neto"), hide: false } as DatatableColumn,
      { field: "bruto", title: this.translate.instant("workerinfo.bruto"), hide: false } as DatatableColumn,
      { field: "status", title: this.translate.instant("workerinfo.status"), hide: false } as DatatableColumn,
    ];
  }

  exportCols = [
    { field: "customerFullName", title: this.translate.instant("customer_plans.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "companyName", title: this.translate.instant("customer_plans.companyName"), hide: false } as DatatableColumn,
    { field: "destination", title: this.translate.instant("customer_plans.destination"), hide: false } as DatatableColumn,
    { field: "departure", title: this.translate.instant("customer_plans.departure"), hide: false } as DatatableColumn,
    { field: "payedInPayments", title: this.translate.instant("customer_plans.payedInPayments"), hide: false } as DatatableColumn,
    { field: "entityName", title: this.translate.instant("customer_plans.entityName"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("customer_plans.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("customer_plans.bruto"), hide: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("customer_plans.status"), hide: false } as DatatableColumn,
  ];

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
}
