import { UserService } from "../service/user.service";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PasswordCustomValidators } from "src/app/shared/validators/password.validator";

@Component({
  selector: "app-user-new-password",
  templateUrl: "./user-new-password.component.html",
})
export class UserNewPasswordComponent implements OnInit {
  constructor(private fb: FormBuilder, private userService: UserService, public translate: TranslateService) {}
  @Input() email!: string;
  form!: FormGroup;
  @Output() notifyWhenSend: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
    this.form = this.fb.group(
      {
        current_password: ["", Validators.required],
        password: ["", [Validators.required, PasswordCustomValidators.passwordValidator]],
        confirm_password: ["", Validators.required],
      },
      { validators: PasswordCustomValidators.checkPasswords() }
    );
  }

  submitForm() {
    if (this.form.valid) {
      this.updatePassword();
    } else {
      this.form.markAllAsTouched();
    }
  }

  updatePassword() {
    const updatePassSub = this.userService.updatePassword(this.fetchData()).subscribe({
      next: () => {
        this.notifyWhenSend.emit(true);
        updatePassSub.unsubscribe();
        showMessage(PopupType.Success, this.translate.instant("popup.password_updated_successfully"));
      },
      error: (error) => {
        this.notifyWhenSend.emit(false);
        updatePassSub.unsubscribe();
        showMessage(PopupType.Danger, error.error.errors[0]?.description || error.toString());
      },
    });
  }

  fetchData() {
    const { current_password, password } = this.form.getRawValue();
    const data = {
      email: this.email,
      currentPassword: current_password,
      newPassword: password,
    };
    return data;
  }
}
