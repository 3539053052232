<div>
  <div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
    <button type="button" class="btn btn-info gap-2" (click)="sendVoucher()">
      <icon-send />
      {{ "vaucher.send" | translate }}
    </button>

    <button type="button" class="btn btn-primary gap-2" (click)="printVoucher()" [disabled]="isLoading">
      <icon-printer />
      {{ "vaucher.print" | translate }}
    </button>

    <button type="button" class="btn btn-warning gap-2" (click)="navigateToEditVoucher()">
      <icon-edit />
      {{ "vaucher.edit" | translate }}
    </button>

    <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
  </div>
  <app-voucher-template [voucher]="voucher"></app-voucher-template>
  <modal #modal class="modal-top custom-modal">
    <ng-template #modalBody>
      <app-file-send
        [title]="'voucher_sending_form_title' | translate"
        fileType="voucher"
        [id]="voucher.id"
        [passangers]="voucher.passengers"
        [sendDocumentFn]="voucherService.sendVoucher(this.voucher.id)"
        messageRs="Vaučer je u dodatku imejla. Molim vas da proverite podatke. Potrebno je da ištampan vaučer predate na recepciji.Molim Vas da se raspitate o uslovima putovanja i prelaska granica. Takođe proverite vaša lična dokumenta. To nije obaveza turističke agencije.Planirajte da je vreme ulaska u sobu 14h. Pre toga hotel će dati sobu prema raspoloživosti. Nakon tog vremena morate dobiti sobu. Vreme izlaska iz sobe je 11h poslednjeg dana"
        messageEn="The voucher is attached to the email. Please check the details. You need to present the printed voucher at the reception. Please inquire about the travel conditions and border crossing. Also, check your personal documents. This is not the responsibility of the travel agency. Please plan for the check-in time to be at 2 PM. Before that, the hotel will provide a room based on availability. After that time, you must receive your room. The check-out time is at 11 AM on the last day"
        (notifyWhenSend)="finishSending()"
      >
      <h2>{{ "contract.passangers_information" | translate }}:</h2>
      <table class="w-full border border-gray-400 table-auto mb-4">
        <thead>
          <tr class="border border-gray-300 bg-gray-100">
            <th class="px-4 py-2">{{ "contractsendemail.name" | translate }}</th>
            <th class="px-4 py-2">{{ "contractsendemail.surname" | translate }}</th>
            <th class="px-4 py-2">{{ "contractsendemail.email" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let passenger of voucher.passengers">
            <td class="border px-4 py-2">{{ passenger.name }}</td>
            <td class="border px-4 py-2">{{ passenger.surname }}</td>
            <td class="border px-4 py-2">{{ passenger.email }}</td>
          </tr>
        </tbody>
      </table>
      <h2 class="mb-2">{{ "contract.traveller_information" | translate }} :</h2>
      <div class="flex">
        <table class="w-full border border-gray-400 table-auto">
          <tbody>
            <tr class="border border-gray-300 bg-gray-50">
              <td class="p-2 border-b border-gray-300">{{ "vouchersendemail.hotel" | translate }}: {{ this.voucher.hotelLabel }}</td>
              <td class="p-2 border-b border-gray-300">{{ "vouchersendemail.agent" | translate }}: {{ this.voucher.agent }}</td>
            </tr>
            <tr class="border border-gray-300 bg-gray-50">
              <td class="p-2 border-b border-gray-300">{{ "vouchersendemail.checkin" | translate }}: {{ this.voucher.checkin | date : "dd.MM.yyyy" }}</td>
              <td class="p-2 border-b border-gray-300">{{ "vouchersendemail.checkout" | translate }}: {{ this.voucher.checkout | date : "dd.MM.yyyy" }}</td>
            </tr>
            <tr class="border border-gray-300 bg-gray-50">
              <td class="p-2 border-b border-gray-300">{{ "vouchersendemail.createdate" | translate }}: {{ this.voucher.createDate | date : "dd.MM.yyyy" }}</td>
              <td class="p-2 border-b border-gray-300">{{ "vouchersendemail.rooms" | translate }}: {{ this.voucher.roomService }}</td>
            </tr>
            <tr class="border border-gray-300 bg-gray-50">
              <td class="p-2 border-b border-gray-300">{{ "vouchersendemail.createbyfullname" | translate }}: {{ this.voucher.createdByFullName }}</td>
              <td class="p-2 border-b border-gray-300">{{ "vouchersendemail.transport" | translate }}: {{ this.voucher.transport }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </app-file-send>
    </ng-template>
  </modal>
</div>
