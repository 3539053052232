import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "../../customers/models/customer";
import { OtherServices } from "./../../other-services/models/otherservices";
import { OtherServicesService } from "../../other-services/services/other-services.service";

@Component({
  selector: "app-other-services-quick-view",
  templateUrl: "./other-services-quick-view.component.html",
  styleUrls: ["./other-services-quick-view.component.css"],
})
export class OtherServicesQuickViewComponent implements OnInit {
  @Input() data!: OtherServices;
  @Input() id!: number;

  constructor(public translate: TranslateService, private otherServices: OtherServicesService) {}

  ngOnInit(): void {
    if (this.id) {
      const getSubscription = this.otherServices.getOtherServiceData(this.id).subscribe((otherService) => {
        getSubscription.unsubscribe();
        this.data = otherService;
      });
    }
  }

  getReservationNumber(pass: Customer[]) {
    if (pass) {
      const arr = pass.map((passenger) => passenger.reservationNumber);
      return arr.join(", ");
    }
    return "";
  }
}
