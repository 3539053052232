import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Customer } from 'src/app/domain/customers/models/customer';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private translate: TranslateService) {}

  validateMainCustomer(passangerList: Customer[]): Customer | null {
    const mainCustomer = passangerList.find((e: Customer) => e.mainCustomer);
    if (!mainCustomer) {
      return null;
    }
    if (!mainCustomer.name || !mainCustomer.surname) {
      showMessage(PopupType.Danger, this.translate.instant("popup.main_customer_is_required")); 
      return null;
    }

    return mainCustomer;
  }
  validatePassanger(mainCustomer: Customer, isPassangerListValid: boolean): boolean {
    if (!mainCustomer.name || !mainCustomer.surname || !isPassangerListValid) {
      showMessage(PopupType.Danger, this.translate.instant("popup.main_customer_is_required"));
      return false;
    }
    return true;
  }
  
}
