<div hlMenu class="!block">
  <button
    type="button"
    hlMenuButton
    class="group relative block flex items-center justify-between rounded-md bg-gray-200 pr-2"
    [ngClass]="{
     'bg-gray-200 border border-gray-400': checked, 
     'bg-white border border-gray-200': !checked
   }"
    [ngxTippy]="tooltipHTMLTemplate"
    [tippyProps]="{
      trigger: 'click',
      duration: 1000,
      interactive: true
    }"
    (click)="navigateToETuristTable($event)"
  >
    <img class="w-18 h-9" src="assets/images/eturista-logo.png" />

    <span class="flex items-center justify-center">
      <!-- Pulsing effect -->
      <span class="relative flex h-full w-full">
        <span class="absolute inline-flex h-full w-full animate-ping rounded-full opacity-75" [ngClass]="{'bg-success/50': checked, 'bg-danger/50': !checked}"></span>
        <span class="relative inline-flex h-[6px] w-[6px] rounded-full" [ngClass]="{'bg-success': checked, 'bg-danger': !checked}"></span>
      </span>
    </span>
  </button>
  <template #tooltipHTMLTemplate>
    <span [innerHtml]="message"></span>
  </template>
</div>
