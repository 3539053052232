import { Store } from "@ngrx/store";
import { DatePipe } from "@angular/common";
import { DailySummary } from "./models/dailysummary";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DailySummaryService } from "./service/daily-summary.service";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-daily-actions",
  templateUrl: "./daily-actions.component.html",
  styleUrls: ["./daily-actions.component.css"],
  animations: [toggleAnimation],
  providers: [DatePipe],
})
export class DailyActionsComponent implements OnInit {
  @ViewChild("modal") modal!: ModalComponent;
  from: Date = new Date("2024-05-25");
  pageSize = 10;
  totalRows = 100;
  loading = false;
  items: DailySummary[] = [];
  cols: DatatableColumn[] = [];
  mergeMode = false;
  DailyActionsid = 0;

  constructor(public datePipe: DatePipe, private dailySummaryService: DailySummaryService, private translate: TranslateService, public store: Store<AppState>) {}

  ngOnInit() {
    this.loadDailySummaries();
    this.getColumns();
  }

  loadDailySummaries() {
    const formattedDate = this.from.toISOString();
    this.loading = true;
    this.dailySummaryService.getDailySummaryForDate(formattedDate).subscribe({
      next: (data) => {
        this.items = data;
        this.loading = false;
      },
      error: (error) => {
        console.error("Error fetching daily summaries", error);
        this.loading = false;
      },
    });
  }

  getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("daily_summary.id"), isUnique: true, hide: false } as DatatableColumn,
      { field: "agent", title: this.translate.instant("daily_summary.agent"), hide: false } as DatatableColumn,
      { field: "customer", title: this.translate.instant("daily_summary.customer"), hide: false } as DatatableColumn,
      { field: "timestamp", title: this.translate.instant("daily_summary.timestamp"), hide: false } as DatatableColumn,
      { field: "totalSum", title: this.translate.instant("daily_summary.totalSum"), hide: false } as DatatableColumn,
    ];
  }

  getSumOfSuma(): number {
    return this.items.reduce((sum, item) => sum + item.totalSum, 0);
  }

  updateColumn(col: any) {
    // Implement column update logic
  }
}
