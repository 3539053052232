<div class="modal-overlay">
  <div class="modal-content">
    <div class="mx-auto w-full">
      <app-file-send
        [title]="'invoicesendemail' | translate"
        fileType="payment"
        [id]="plan.id"
        [passangers]="[plan.customer]"
        [sendDocumentFn]="paymentService.sendPayment(this.plan.id)"
        messageRs="Racun se nalazi u dodatku maila"
        messageEn="Invoice is in the attachement of the mail"
        (notifyWhenSend)="finishSending()"
      >
        <div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
          <button type="button" class="btn btn-primary gap-2" (click)="printInvoice()" [disabled]="isLoading">
            <icon-printer />
            {{ "invoice_preview.print" | translate }}
          </button>
          <button type="button" class="btn btn-danger gap-2" (click)="dismiss()" [disabled]="isLoading">
            <icon-x />
            {{ "invoice_preview.close" | translate }}
          </button>
        </div>
        <div class="panel" id="payment">
          <div class="d-flex justify-content-between align-items-center">
            <div class="flex flex-wrap items-center justify-between">
              <div *ngIf="plan.createdBy.officeBranch.logoImage">
                <img [src]="'http://' + plan.createdBy.officeBranch.logoImage.url" alt="" class="w-25 h-10" />
              </div>
              <div class="space-y-1 text-white-dark">
                <div class="w-full">
                  <div class="space-y-1 text-white-dark">
                    <div class="flex flex-wrap justify-end" *ngIf="plan.createdBy.officeBranch.fullAddress">
                      <span class="mr-2">{{ plan.createdBy.officeBranch.fullAddress }}</span>
                      <icon-home />
                    </div>
                    <div class="flex flex-wrap justify-end">
                      <span class="mr-2">{{ plan.createdBy.officeBranch.phoneNumber }} </span>
                      <icon-phone />
                    </div>
                    <div class="flex flex-wrap justify-end">
                      <span class="mr-2">{{ plan.createdBy.officeBranch.email }} </span>
                      <icon-mail />
                    </div>
                    <div class="flex flex-wrap justify-end">
                      <span class="mr-2">https://{{ plan.createdBy.officeBranch.siteURL }} </span>
                      <icon-chrome />
                    </div>
                    <div class="flex flex-wrap justify-end">
                      <span class="mr-2">{{ "giro-account" | translate }}: {{ plan.createdBy.officeBranch.bankAccount }},PIB:{{ plan.createdBy.officeBranch.pib }} </span>
                      <icon-zip-file />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 flex flex-col">
            <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
              <label class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.customerFullName" | translate }}: </label>
              <div class="w-2/3 lg:w-[250px]">{{ plan.customer.name + " " + plan.customer.surname }}</div>
            </div>
            <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
              <label class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.email" | translate }}: </label>
              <div class="w-2/3 lg:w-[250px]">{{ plan.customer.email }}</div>
            </div>
            <div class="mt-2 flex flex-col lg:flex-row lg:items-center" *ngIf="isOnlyAvans">
              <label class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.total" | translate }}: </label>
              <div class="w-2/3 lg:w-[250px]">
                {{ (plan.bruto || 0) - (plan.avans || 0) | currency : "EUR" }}
              </div>
            </div>
            <div class="mt-2 flex flex-col lg:flex-row lg:items-center">
              <label class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.avans" | translate }}: </label>
              <div class="w-2/3 lg:w-[250px]">{{ plan.avans ?? 0 | currency : "EUR" }}</div>
            </div>
            <!-- <div class="mt-4 flex items-center justify-between">
              <p>{{ "paymentdetails.paymentType" | translate }}:</p>
              <div>{{ plan.paymentType.title }}</div>
            </div> -->
            <hr class="my-2 border-[#e0e6ed] dark:border-[#1b2e4b]" />
            <div class="mt-2 flex flex-col text-red-500 lg:flex-row lg:items-center">
              <label for="checkout" class="mb-1 lg:mb-0 lg:ltr:mr-2 lg:rtl:ml-2">{{ "paymentdetails.toBePaid" | translate }}: </label>
              <div class="w-2/3 lg:w-[250px]">{{ (plan.bruto || 0) - (plan.avans || 0) | currency : "EUR" }}</div>
            </div>
          </div>
          <div class="mt-2">
            <div class="mx-auto rounded-lg">
              <div class="flex justify-between">
                <div>
                  <p>
                    <strong>{{ "paymentdetails.dateOfPayment" | translate }}:</strong> {{ getCurrentDate() | date : "dd.MM.yyyy" }}
                  </p>
                  <p>
                    <strong>{{ "paymentdetails.agent" | translate }}:</strong> {{ plan.createdBy.name + " " + plan.createdBy.surname }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-file-send>
    </div>
  </div>
</div>
