<div class="my-5 grid grid-cols-1 gap-5 md:grid-cols-3">
  <ng-container *ngFor="let passengerType of passengerTypes">
    <div class="flex flex-col items-center">
      <label>{{ passengerType.label }}</label>
      <ngx-number-spinner
        [value]="getPassengerCount(passengerType.type)"
        [min]="passengerType.type === 'adults' ? 1 : 0"
        [max]="20"
        class="touchspin"
        (change)="changePassengerCount($event, passengerType)"
      ></ngx-number-spinner>
    </div>
  </ng-container>
</div>
<div class="m-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4" *ngIf="passengers.length > 0">
  <ng-container *ngFor="let item of passengers; index as i">
    <div
      [ngClass]="{ 'bg-green-100': editPassanger && item.guid === editPassanger.guid }"
      class="flex min-w-[625px] items-center justify-between rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b]"
    >
      <div class="flex items-center">
        <div class="user-profile">
          <img [src]="item.imageUrl" alt="" class="h-8 w-8 rounded-md object-cover" />
        </div>
        <div class="ml-2">{{ item.name }} {{ item.surname }}</div>
      </div>
      <div *ngIf="item.isMainCustomer || item.mainCustomer">
        <span *ngIf="item.isMainCustomer; else alternateTranslation">
          {{ "customer_checkout.main_customer" | translate }}
        </span>
        <ng-template #alternateTranslation>
          {{ "customer_checkout.not_main_customer" | translate }}
        </ng-template>
      </div>
      <ng-template #backUp> </ng-template>
      <div>
        <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" ngxTippy="Edit" (click)="toEditPassanger(item)">
          <icon-pencil />
        </a>
      </div>
      <div>
        <a *ngIf="!item.mainCustomer" type="button" ngxTippy="Delete" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="removePassanger(item.guid)">
          <icon-trash-lines />
        </a>
      </div>
    </div>
  </ng-container>
</div>
<div class="relative" *ngIf="editPassanger">
  <app-customer-create [defaultCustomer]="editPassanger" (submitCustomer)="handlePassanger($event)" [passanger]="true" [withReservation]="withReservation"></app-customer-create>
</div>
