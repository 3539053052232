import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-group-travel-add-passanger",
  templateUrl: "./group-travel-add-passanger.component.html",
})
export class GroupTravelAddPassangerComponent {
  @Input() groupTravelId!: number;
  constructor(private route: ActivatedRoute) {}
}
